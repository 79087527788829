import { CREATE_PLAN, CREATE_PLAN_SUCCESS, CREATE_PLAN_FAIL } from "constants/actionTypes/plan/create";

const createPlan = payload => {
  return { type: CREATE_PLAN, payload };
};

export const createPlanSuccess = payload => {
  return { type: CREATE_PLAN_SUCCESS, isLoading: false, payload };
};

export const createPlanFail = payload => {
  return { type: CREATE_PLAN_FAIL, payload };
};

export default createPlan;
