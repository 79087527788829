import React from 'react';
import { Field } from 'redux-form';
import { Input, FormField } from '@amzn/awsui-components-react/';

const renderField = (props) => {

  const {
    input: { onChange, onBlur, value },
    label,
    type,
    placeholder,
    description,
    meta: { touched, error },
    autofocus = false,
    stretch,
    required,
    disabled,
    hintText
  } = props;
  console.log(props)

  const onInput = event => {
    onChange(event.detail.value);
  };

  const myBlur = () => {
    console.log("Yeah blurr")
    console.log(onBlur())
    console.log(onBlur)
  };

  return (
    <FormField
      className="awsui-util-d-b awsui-util-mb-m"
      description={description}
      errorText={touched && error}
      stretch={stretch}
      label={label}
      hintText={hintText}
    >
      <Input
        onInput={onInput}
        onChange={onChange}
        onBlur={myBlur}
        placeholder={placeholder}
        type={type}
        autofocus={autofocus}
        required={required}
        disabled={disabled}
        value={value}
      />
    </FormField>
  );
};

const PolarisInputField = ({
  label,
  name,
  description,
  placeholder,
  type = 'text',
  autofocus = false,
  stretch = true,
  required = false,
  value,
  disabled = false,
  hintText,
  validate
}) => {
  return (
    <Field
      label={label}
      name={name}
      description={description}
      component={renderField}
      autofocus={autofocus}
      placeholder={placeholder}
      stretch={stretch}
      type={type}
      required={required}
      value={value}
      disabled={disabled}
      hintText={hintText}
      validate={validate}
    />
  );
};

export default PolarisInputField;
