import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Wizard from 'views/components/Wizard';

import fetchPlan from 'actions/plan';
import {PARTNER_PLANNING_STEPS} from './config.js';
import {FeedbackHeader} from "views/components/FeedbackHeader";

//TODO: Finish/

class PartnerPlanning extends Component {
  componentDidMount() {
    this.props.fetchPlan(this.props.match.params.planId);
  }

  render() {
    return (
      <div>
        <div>
          {FeedbackHeader(null)}
          {/*todo take off null, for a history*/}
        </div>

        <Wizard stepInfo={PARTNER_PLANNING_STEPS}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    fetchPlan: planId => dispatch(fetchPlan(planId)),
    dispatch
  };
};

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerPlanning);

export default withRouter(connectDecoratedComponent);
