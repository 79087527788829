/*Handles checking for a Cognito Web Token in either the URL or the browser cookies.
* If it doesnt exist, we redirect the user to authenticate */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {AUTH_INTERVAL_MS, checkTokenExpiration} from 'constants/functions';


export const AuthComponent = withRouter(props => <AuthChecker {...props}/>);

/**
 * AuthChecker checks for a authorization cookie, ensures its valid, and sets and interval to maintain validity
 * throughout the use of the application. if there is no cookie or it's expiried, redirect to Cognito Auth.
 */
class AuthChecker extends React.Component {

  initAuthChecker() {
    this.Timer = setInterval(() => checkTokenExpiration() , AUTH_INTERVAL_MS);
  }

  render(){
    //this component lives in the background.
    return (
      checkTokenExpiration(),
      this.initAuthChecker(),
      null
      // <p>Cookie pulled from browser: {getIdentityFromCognitoCookie()}</p>
    )
  }
}


