import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import user from "reducers/user";
import plan from "reducers/plan";
import plans from "reducers/plans";

const rootReducer = combineReducers({
  user,
  plan,
  plans,
  form: formReducer
});

export default rootReducer;
