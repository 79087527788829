import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppLayout, Button, ColumnLayout, Form, FormSection } from '@amzn/awsui-components-react/';
import { reduxForm } from 'redux-form';
import uuidv4 from 'uuid/v4';

import PolarisInputField from 'views/components/Form/PolarisInputField';
import PolarisSelectField from 'views/components/Form/PolarisSelectField';

import Notifications from 'views/components/Dashboard/Notifications';

import { createLoadingNotification } from 'actions/notifications';
import createPlan from 'actions/plan/create';
import { getOptions } from 'constants/functions';
import { validateForm } from 'views/components/Dashboard/Create/validation';

const FORM_NAME = 'CreatePartnerPlanModal';

//TODO: Finish converting the fields

class CreatePartnerPlan extends Component {
  render() {
    const { handleSubmit, dispatch, history } = this.props;
    const calculatedOptions = getOptions(new Date().getFullYear());
    const ImportPartnerPlanForm = (
      <Form
        onSubmit={handleSubmit}
        actions={
          <div className="awsui-util-f-r">
            <Button
              onClick={() => {
                history.push('/');
              }}
              variant="link"
              text="Cancel"
            />
            <Button
              onClick={handleSubmit(values => {
                const notificationId = uuidv4();
                values['notificationId'] = notificationId;
                dispatch(
                  createLoadingNotification({
                    notificationId,
                    content: `Creating a new business plan.`
                  })
                );
                dispatch(createPlan(values));
                history.push('/');
              })}
              variant="primary"
              text="Submit"
            />
          </div>
        }
      >
        <FormSection header="Create Business Plan">
          <div>
            <p>Lets get started. Give your business plan a name!</p>
          </div>

          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <PolarisInputField label="Business Plan Name" name="reportName" placeholder="Enter name" />
              <PolarisSelectField
                label="Year"
                name="year"
                options={calculatedOptions}
                placeholder="Please select a year."
              />
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    );
    return (
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={ImportPartnerPlanForm}
        notifications={<Notifications />}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  validate: validateForm
})(CreatePartnerPlan);

const connectDecoratedComponent = connect(
  null,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
