import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, ColumnLayout, Form, FormSection} from '@amzn/awsui-components-react/';
import {reduxForm} from 'redux-form';

import PolarisInputField from 'views/components/Form/PolarisInputField';

import fetchPlan from 'actions/plan';
import dispatchSubmitForm, {redirectHome} from 'constants/functions';
import {SALES_AND_TECHNICAL_ENABLEMENT} from 'constants/forms';
import {positiveNumberValidation, wholeNumberValidation} from "constants/forms/validation";
import {validateForm} from './validation';
import {FeedbackHeader} from "views/components/FeedbackHeader";

const FORM_NAME = SALES_AND_TECHNICAL_ENABLEMENT;

//TODO:
class SalesAndTechnicalEnablement extends Component {
  componentDidMount() {
    this.props.fetchPlan(this.props.match.params.planId);
  }

  render() {
    const {handleSubmit, dispatch, planId, formName, history, year} = this.props;

    return (
      <div>
        <div>
          {FeedbackHeader(history)}
        </div>

        <Form
          onSubmit={handleSubmit}
          actions={
            <div>
              <Button text="Cancel" variant="link" onClick={() => redirectHome(history, planId)}/>
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: false,
                      planId,
                      formName
                    },
                    dispatch
                  );
                })}
                text="Save as Draft"
                variant="primary"
              />
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: true,
                      planId,
                      formName
                    },
                    dispatch
                  );
                  this.props.history.push('/plans/' + planId);
                })}
                text="Submit"
                variant="primary"
              />
            </div>
          }
        >
          <FormSection header="Sales And Technical Enablement">
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="AWS Business Trainings"
                  description={`${year} Goal`}
                  name="awsBusinessTrainingsGoal"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  description="Q1"
                  name="awsBusinessTrainingQ1"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q2"
                  name="awsBusinessTrainingQ2"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q3"
                  name="awsBusinessTrainingQ3"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q4"
                  name="awsBusinessTrainingQ4"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="AWS Technical Trainings"
                  description={`${year} Goal`}
                  name="awsTechnicalTrainingsGoal"
                  placeholder="#"
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  description="Q1"
                  name="awsTechnicalTrainingQ1"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q2"
                  name="awsTechnicalTrainingQ2"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q3"
                  name="awsTechnicalTrainingQ3"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q4"
                  name="awsTechnicalTrainingQ4"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Associate Certifications"
                  description={`${year} Goal`}
                  name="associateCertificationsGoal"
                  placeholder="#"
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  description="Q1"
                  name="associateCertificationsQ1"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q2"
                  name="associateCertificationsQ2"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q3"
                  name="associateCertificationsQ3"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q4"
                  name="associateCertificationsQ4"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Professional Certifications"
                  description={`${year} Goal`}
                  name="professionalCertificationsGoal"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  description="Q1"
                  name="professionalCertificationsQ1"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q2"
                  name="professionalCertificationsQ2"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q3"
                  name="professionalCertificationsQ3"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q4"
                  name="professionalCertificationsQ4"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Service Delivery Programs"
                  description={`${year} Goal`}
                  name="serviceDeliveryProgramsGoal"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  description="Q1"
                  name="serviceDeliveryProgramsQ1"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q2"
                  name="serviceDeliveryProgramsQ2"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q3"
                  name="serviceDeliveryProgramsQ3"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q4"
                  name="serviceDeliveryProgramsQ4"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Competency Programs"
                  description={`${year} Goal`}
                  name="competencyProgramsGoal"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  description="Q1"
                  name="competencyProgramsQ1"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q2"
                  name="competencyProgramsQ2"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q3"
                  name="competencyProgramsQ3"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q4"
                  name="competencyProgramsQ4"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Other Programs"
                  description={`${year} Goal`}
                  name="otherProgramsGoal"
                  placeholder="#"
                />
              </div>
            </ColumnLayout>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  description="Q1"
                  name="otherProgramsQ1"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q2"
                  name="otherProgramsQ2"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q3"
                  name="otherProgramsQ3"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  description="Q4"
                  name="otherProgramsQ4"
                  placeholder="#"
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
              </div>
            </ColumnLayout>
            <div>
              <p className="awsui-text-secondary">Business Training Classes</p>
            </div>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Course Name"
                  name="businessTrainingCourseName"
                  placeholder="Enter here..."
                />
                <PolarisInputField
                  label="# Attendees"
                  name="businessTrainingCourseAttendee"
                  placeholder="Enter here..."
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  label="Location"
                  name="businessTrainingCourseLocation"
                  placeholder="Enter here..."
                />
                <PolarisInputField
                  label="QTR"
                  name="businessTrainingCourseQT"
                  placeholder="Enter here..."
                />
              </div>
            </ColumnLayout>
            <div>
              <p className="awsui-text-secondary">Technical Training Classes</p>
            </div>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Course Name"
                  name="technicalTrainingCourseName"
                  placeholder="Enter here..."
                />
                <PolarisInputField
                  label="# Attendees"
                  name="technicalTrainingCourseAttendee"
                  placeholder="Enter here..."
                  validate={[positiveNumberValidation, wholeNumberValidation]}
                />
                <PolarisInputField
                  label="Location"
                  name="technicalTrainingCourseLocation"
                  placeholder="Enter here..."
                />
                <PolarisInputField
                  label="QTR"
                  name="technicalTrainingCourseQT"
                  placeholder="Enter here..."
                />
              </div>
            </ColumnLayout>
            <div>
              <p className="awsui-text-secondary">Program Obtainment</p>
            </div>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Program Name"
                  name="programObtainmentName"
                  placeholder="Service Delivery Program"
                />
                <PolarisInputField
                  label="Specific Program"
                  name="programObtainmentSpecificProgram"
                  placeholder="Lambda"
                />
                <PolarisInputField
                  label="QTR"
                  name="programObtainmentQTR"
                  placeholder="Enter here..."
                />
              </div>
            </ColumnLayout>
            <div>
              <p className="awsui-text-secondary">Tier Advancement</p>
            </div>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Tier Name"
                  name="tierAdvancementName"
                  placeholder="Enter here..."
                />
                <PolarisInputField
                  label="Gaps"
                  name="tierAdvancementGaps"
                  placeholder="Enter here..."
                />
                <PolarisInputField
                  label="QTR"
                  name="tierAdvancementQTR"
                  placeholder="Enter here..."
                />
              </div>
            </ColumnLayout>
          </FormSection>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  //...state.plan.plan.forms[FORM_NAME]
  return {
    planId: state.plan.plan.id,
    year: state.plan.plan.year,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    fetchPlan: planId => dispatch(fetchPlan(planId)),
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: validateForm
})(SalesAndTechnicalEnablement);
const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
