import React from "react";
import { Button } from "@amzn/awsui-components-react/";
import Goal from "./Goal";

const Goals = ({ fields }) => {
  console.log(fields);
  return (
    <React.Fragment>
      {fields.map(Goal)}
      <Button
        onClick={() => {
          fields.push({});
        }}
      >
        Add Partnership Goal
      </Button>
    </React.Fragment>
  );
};

export default Goals;
