export default function PartnerPlanning(report, year) {
  console.log("Started compiling Partner Planning section")

  let tableBodyAPNResource = [];
  (report["mdf"+(year-1)] || report["mdf"+(year-2)] || report["mdf"+(year-3)] || report["poc"+(year-1)] || report["poc"+(year-2)] || report["poc"+(year-3)] || report["map"+(year-1)] || report["map"+(year-2)] || report["map"+(year-3)] || report["promoCredits"+(year-1)] || report["promoCredits"+(year-2)] || report["promoCredits"+(year-3)] || report["pss"+(year-1)] || report["pss"+(year-2)] || report["pss"+(year-3)] || report["tf"+(year-1)] || report["tf"+(year-2)] || report["tf"+(year-3)]) && tableBodyAPNResource.push([{text: "", color: '#FFFFFF', fillColor: '#4a80bd'}, {text: (year-1), color: '#FFFFFF', fillColor: '#4a80bd'}, {text: (year-2), color: '#FFFFFF', fillColor: '#4a80bd'}, {text: (year-3), color: '#FFFFFF', fillColor: '#4a80bd'}]);
  (report["mdf"+(year-1)] || report["mdf"+(year-2)] || report["mdf"+(year-3)]) && tableBodyAPNResource.push(["MDF", report["mdf"+(year-1)] ? report["mdf"+(year-1)] : "", report["mdf"+(year-2)] ? report["mdf"+(year-2)] : "", report["mdf"+(year-3)] ? report["mdf"+(year-3)] : ""]);
  (report["poc"+(year-1)] || report["poc"+(year-2)] || report["poc"+(year-3)]) && tableBodyAPNResource.push(["POC", report["poc"+(year-1)] ? report["poc"+(year-1)] : "", report["poc"+(year-2)] ? report["poc"+(year-2)] : "", report["poc"+(year-3)] ? report["poc"+(year-3)] : ""]);
  (report["map"+(year-1)] || report["map"+(year-2)] || report["map"+(year-3)]) && tableBodyAPNResource.push(["MAP", report["map"+(year-1)] ? report["map"+(year-1)] : "", report["map"+(year-2)] ? report["map"+(year-2)] : "", report["map"+(year-3)] ? report["map"+(year-3)] : ""]);
  (report["promoCredits"+(year-1)] || report["promoCredits"+(year-2)] || report["promoCredits"+(year-3)]) && tableBodyAPNResource.push(["AWS Promo Credits", report["promoCredits"+(year-1)] ? report["promoCredits"+(year-1)] : "", report["promoCredits"+(year-2)] ? report["promoCredits"+(year-2)] : "", report["promoCredits"+(year-3)] ? report["promoCredits"+(year-3)] : ""]);
  (report["pss"+(year-1)] || report["pss"+(year-2)] || report["pss"+(year-3)]) && tableBodyAPNResource.push(["ProServe SuperCharge", report["pss"+(year-1)] ? report["pss"+(year-1)] : "", report["pss"+(year-2)] ? report["pss"+(year-2)] : "", report["pss"+(year-3)] ? report["pss"+(year-3)] : ""]);
  (report["tf"+(year-1)] || report["tf"+(year-2)] || report["tf"+(year-3)]) && tableBodyAPNResource.push(["Training Funds", report["tf"+(year-1)] ? report["tf"+(year-1)] : "", report["tf"+(year-2)] ? report["tf"+(year-2)] : "", report["tf"+(year-3)] ? report["tf"+(year-3)] : ""]);
  
  let GTMInitiatives = report && report.completed && report.partnerGoals && report.partnerGoals.length ? 
    [
      {
        text: `${report.partnerGoals ? "Partner's top initiatives for next year" : ""}`, style: "sectionSortaSubheader"
      }
    ] : null;
  let goalNum = 1;
  console.log(JSON.stringify(report.partnerGoals))
  if(report.partnerGoals){
    for (let goal of report.partnerGoals){
      console.log(goal.goal)
      GTMInitiatives.push([
        [
          {text: "Initiative " + goalNum, style: "sectionSubheader"}
        ],
        [
          {text: `${goal.goal ? "Initiative Name: " : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.goal ? goal.goal : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.goalDescription ? "Initiative Description:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.goalDescription ? goal.goalDescription : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.taskToComplete ? "Task To Complete:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.taskToComplete ? goal.taskToComplete : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.partner ? "Partner:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.partner ? goal.partner : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.metrics ? "Metrics:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.metrics ? goal.metrics : ""}`, style: "sectionBody"}
        ]
      ]);
      goalNum++;
    }
  }

  let tableBodyRevenue = [];
  
  report.enterprise && tableBodyRevenue.push([{text: "Enterprise:", color: '#FFFFFF', fillColor: '#4a80bd'}, {text: `${report.enterprise ? report.enterprise : ""}`}])
  report.upperMidMarket && tableBodyRevenue.push([{text: "Upper mid-market:", color: '#FFFFFF', fillColor: '#4a80bd'}, {text: `${report.upperMidMarket ? report.upperMidMarket : ""}`}])
  report.lowerMidMarket && tableBodyRevenue.push([{text: "Lower mid-market:", color: '#FFFFFF', fillColor: '#4a80bd'}, {text: `${report.lowerMidMarket ? report.lowerMidMarket : ""}`}])
  report.smb && tableBodyRevenue.push([{text: "SMB:", color: '#FFFFFF', fillColor: '#4a80bd'}, {text: `${report.smb ? report.smb : ""}`}])

  let partnerPlanning =
    report && report.completed
      ? [
          {
            text: "Partner Planning",
            style: "sectionHeader"
          },
          {
            text: "Executive Summary: ",
            style: "sectionSubheader"
          },
          {
            text: report.executiveSummary ? report.executiveSummary : "",
            style: "sectionBody"
          },
          {
            text: "APN Resource Utilization:",
            style: "sectionSubheader"
          },
          tableBodyAPNResource.length ? {
            table: {
              margin: [0,5,0,0],
              widths: ['auto', '*', '*', '*'],
              body: tableBodyAPNResource
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
              }
            }
          } : {},

          GTMInitiatives ? {
            text: "Top GTM Initiatives",                                                                 //TOP GTM INITIATIVES
            style: "sectionSubheader"
          } : {},
          GTMInitiatives,


          report.mapsToAWS || report.consulting || report.managedServices || report.verticals || report.publicSector || report.awsSuperpowers || report.describeBAS || report.describeMS || report.describeTopVerticals || report.describePS || report.describeSuperpowers ? 
          [ 
            { 
              text: "Partner Business Model",                                                                 //PARTNER BUSINESS MODEL
              style: "sectionSubheader"
            },
            {
              text: `${report.mapsToAWS ? "Organization:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.mapsToAWS ? report.mapsToAWS : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.consulting ? "Consulting:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.consulting ? report.consulting : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.managedServices ? "Managed Services:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.managedServices ? report.managedServices : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.verticals ? "Verticals:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.verticals ? report.verticals : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.publicSector ? "Public Sector (%):" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.publicSector ? report.publicSector : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.awsSuperpowers ? "AWS Superpowers:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsSuperpowers ? report.awsSuperpowers : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.describeBAS ? "Describe the consulting business and solutions:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.describeBAS ? report.describeBAS : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.describeMS ? "Describe managed mervices - Infra only? Specialty Managed Services?" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.describeMS ? report.describeMS : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.describeTopVerticals ? "Describe top verticals and what they offer:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.describeTopVerticals ? report.describeTopVerticals : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.describePS ? "Describe the Public Sector presense - Fed, SLED, Education, Non-profit:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.describePS ? report.describePS : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.describeSuperpowers ? "Describe AWS Superpowers. What sets them apart?" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.describeSuperpowers ? report.describeSuperpowers : ""}`,
              style: "sectionBody"
            }
          ] : {},

          tableBodyRevenue.length || report.alignMarketOrganization || report.alignMarketMapToAWS || report.sellersAccredited || report.technicalSellersCertified || report.consultantsCertified || report.inDirectChannels || report.awsReferenceAccounts || report.partnerMakeMoney || report.resellMarginImpact || report.msftLegacyRelationship || report.otherPublicCloudBusinesses || report.percentFromPublicCloud ? [
            { 
              text: `Revenue Breakdown`, 
              style: `sectionSubheader`
            },

            // TABLE HERE
            tableBodyRevenue.length ? {
              table: {
                margin: [0,5,0,0],
                widths: ['auto', '*'],
                body: tableBodyRevenue
              },
              layout: {
                hLineWidth: function(i, node) {
                  return i === 0 || i === node.table.body.length ? 2 : 1;
                },
                vLineWidth: function(i, node) {
                  return i === 0 || i === node.table.widths.length ? 2 : 1;
                },
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
                }
              }
            } : {},


            {
              text: `${report.alignMarketOrganization ? "Organization:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.alignMarketOrganization ? report.alignMarketOrganization : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.alignMarketMapToAWS ? "Maps to AWS:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.alignMarketMapToAWS ? report.alignMarketMapToAWS : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.sellersAccredited ? "Sellers/Accredited:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.sellersAccredited ? report.sellersAccredited : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.technicalSellersCertified ? "Technical Sellers/Certified:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.technicalSellersCertified ? report.technicalSellersCertified : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.consultantsCertified ? "Consultants/Certified:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.consultantsCertified ? report.consultantsCertified : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.inDirectChannels ? "In Direct Channels:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.inDirectChannels ? report.inDirectChannels : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.awsReferenceAccounts ? "AWS Reference Accounts:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsReferenceAccounts ? report.awsReferenceAccounts : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.partnerMakeMoney ? "How does your partner make money?" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.partnerMakeMoney ? report.partnerMakeMoney : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.resellMarginImpact ? "What impact does resell (margin) have on your partner's business?" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.resellMarginImpact ? report.resellMarginImpact : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.msftLegacyRelationship ? "What is their relationship with Microsoft Azure and Legacy businesses?" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.msftLegacyRelationship ? report.msftLegacyRelationship : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.otherPublicCloudBusinesses ? "Are there other public cloud businesses?" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.otherPublicCloudBusinesses ? report.otherPublicCloudBusinesses : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.percentFromPublicCloud ? "What % of their overall business comes from Public Cloud(s)" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.percentFromPublicCloud ? report.percentFromPublicCloud : ""}`,
              style: "sectionBody"
            },
          ] : {},

          
          report.awsNda || report.awsResellerAgreement || report.awsMspAgreement || report.awsServicesMsa || report.awsMarketplaceAgreement || report.awsSupportLevel || report.awsSolutionsSpace || report.awsPracticePlan || report.gtmMaterial ? [
            { 
              text: `Partnership Documents`, 
              style: `sectionSubheader` 
            },
            {
              text: `${report.awsNda ? "AWS NDA:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsNda ? report.awsNda : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.awsResellerAgreement ? "AWS Reseller Agreement:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsResellerAgreement ? report.awsResellerAgreement : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.awsMspAgreement ? "AWS MSP Agreement:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsMspAgreement ? report.awsMspAgreement : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.awsServicesMsa ? "AWS Services MSA:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsServicesMsa ? report.awsServicesMsa : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.awsMarketplaceAgreement ? "AWS Marketplace Agreement:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsMarketplaceAgreement ? report.awsMarketplaceAgreement : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.awsSupportLevel ? "AWS Support Level:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsSupportLevel ? report.awsSupportLevel : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.awsSolutionsSpace ? "AWS Solutions Space:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsSolutionsSpace ? report.awsSolutionsSpace : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.awsPracticePlan ? "AWS Practice Plan:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.awsPracticePlan ? report.awsPracticePlan : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.gtmMaterial ? "GTM Material:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.gtmMaterial ? report.gtmMaterial : ""}`,
              style: "sectionBody"
            },
          ] : {},


          report.sacStrength || report.sacWeakness || report.sacOpportunities || report.sacThreat ? [ 
            { 
              text: `Strengths and Challenges`, 
              style: `sectionSubheader` 
            },
            {
              text: `${report.sacStrengths ? "Stengths:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.sacStrengths ? report.sacStrengths : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.sacWeakness ? "Weakness:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.sacWeakness ? report.sacWeakness : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.sacOpportunities ? "Opportunities:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.sacOpportunities ? report.sacOpportunities : ""}`,
              style: "sectionBody"
            },
            {
              text: `${report.sacThreat ? "Threats:" : ""}`,
              style: "sectionSubsubheader"
            },
            {
              text: `${report.sacThreat ? report.sacThreat : ""}`,
              style: "sectionBody"
            }
          ] : {},
        ]
      : null;
  console.log("Finished compiling Partner Planning section")
  return partnerPlanning;
}
