import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Form, FormSection} from "@amzn/awsui-components-react/";
import {FieldArray, reduxForm} from "redux-form";

import Goals from "./Partner/Goals";
import PartnershipGoals from "./Partnership/Goals";

import fetchPlan from "actions/plan";
import dispatchSubmitForm, {redirectHome} from "constants/functions";

import {PARTNER_GOALS} from "constants/forms";
import {FeedbackHeader} from "views/components/FeedbackHeader";

const FORM_NAME = PARTNER_GOALS;

class PartnerGoals extends Component {
  componentDidMount() {
    this.props.fetchPlan(this.props.match.params.planId);
  }

  render() {
    const {handleSubmit, dispatch, planId, formName, history} = this.props;

    return (
      <div>
        <div>
          {FeedbackHeader(history)}
        </div>

        <Form
          onSubmit={handleSubmit}
          actions={
            <div>
              <Button
                text="Cancel"
                variant="link"
                onClick={() => redirectHome(history, planId)}
              />
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: false,
                      planId,
                      formName
                    },
                    dispatch
                  );
                })}
                text="Save as Draft"
                variant="primary"
              />
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: true,
                      planId,
                      formName
                    },
                    dispatch
                  );
                  this.props.history.push("/plans/" + planId);
                })}
                text="Submit"
                variant="primary"
              />
            </div>
          }
        >
          <FormSection
            header="Partner Goals"
            description="Describe your partner's overall business goals and strategy for next year. This provides the background for how AWS will help your partner achieve their business goals Next, determine what success looks like, and when it will be attained..Examples: Growth initiatives, cost controls, new geographies, competition. These goals can be about the organization of the company, market expansion (verticals, geos, products), how they will be competitive."
          >
            <FieldArray name="partnerGoals" component={Goals}/>
          </FormSection>
          <FormSection
            header="AWS Partnership Goals"
            description="What are your AWS goals for the partner? How will AWS help the partner reach their business goals? (Reference the business goal above)  Be as detailed as possible in the goal description.  These goals will be used to plan activities for the year.  They are owned by the partner and supported by AWS.  Examples: Solutions launched, Segment programs engaged, launched opportunities (could be general or specific opportunities ie Windows workloads, migrations), revenue, tier attainment, certification attainment, program participation (ie Competency Program, Solution Provider Program, MSP, etc)."
          >
            <FieldArray name="awsPartnershipGoals" component={PartnershipGoals}/>
          </FormSection>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    fetchPlan: planId => dispatch(fetchPlan(planId)),
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(PartnerGoals);
const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
