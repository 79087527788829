import {
  ACKNOWLEDGE_NOTIFICATION,
  ACKNOWLEDGE_NOTIFICATIONS,
  CREATE_SUCCESS_NOTIFICATION,
  CREATE_LOADING_NOTIFICATION,
  CREATE_ERROR_NOTIFICATION
} from 'constants/actionTypes/notifications';

import { ACTIVE, DISMISSED, INFO, SUCCESS, ERROR } from 'constants/notifications';

const acknowledgeNotification = notificationId => {
  return { type: ACKNOWLEDGE_NOTIFICATION, payload: { notificationId, status: DISMISSED } };
};

export const acknowledgeNotifications = notifications => {
  return { type: ACKNOWLEDGE_NOTIFICATIONS, payload: notifications };
};

export const createLoadingNotification = payload => {
  const notificationId = payload.notificationId;
  const content = payload.content;
  return {
    type: CREATE_LOADING_NOTIFICATION,
    payload: {
      notificationId,
      content,
      date: new Date(),
      type: INFO,
      loading: true,
      header: 'Notification',
      dismissible: false,
      status: ACTIVE
    }
  };
};
export const createSuccessNotification = payload => {
  const notificationId = payload.notificationId;
  const content = payload.content;
  return {
    type: CREATE_SUCCESS_NOTIFICATION,
    payload: {
      notificationId,
      content,
      date: new Date(),
      type: SUCCESS,
      loading: false,
      dismissible: true,
      status: ACTIVE
    }
  };
};

export const createErrorNotification = payload => {
  const notificationId = payload.notificationId;
  const content = payload.content;
  return {
    type: CREATE_ERROR_NOTIFICATION,
    payload: {
      notificationId,
      content,
      date: new Date(),
      type: ERROR,
      loading: false,
      header: 'Notification',
      dismissible: true,
      status: ACTIVE
    }
  };
};

export default acknowledgeNotification;
