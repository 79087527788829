import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, ColumnLayout, Form, FormSection} from "@amzn/awsui-components-react/";
import {reduxForm} from "redux-form";

import PolarisTextAreaField from "views/components/Form/PolarisTextAreaField";
import PolarisSelectField from "views/components/Form/PolarisSelectField";
import {SELECT_OPTIONS} from "config/SelectOptions";

import fetchPlan from "actions/plan";
import dispatchSubmitForm, {redirectHome} from "constants/functions";
import {MARKETING} from "constants/forms";
import {
  decimalNumberValidation,
  wholeNumberValidation,
  zeroToTenValidation
} from "constants/forms/validation";
import {FeedbackHeader} from "views/components/FeedbackHeader";

const FORM_NAME = MARKETING;

//TODO: Finish

class Marketing extends Component {
  componentDidMount() {
    this.props.fetchPlan(this.props.match.params.planId);
  }

  render() {
    const {handleSubmit, dispatch, planId, formName, history} = this.props;
    return (
      <div>
        <div>
          {FeedbackHeader(history)}
        </div>

        <Form
          onSubmit={handleSubmit}
          actions={
            <div>
              <Button
                text="Cancel"
                variant="link"
                onClick={() => redirectHome(history, planId)}
              />
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: false,
                      planId,
                      formName
                    },
                    dispatch
                  );
                })}
                text="Save as Draft"
                variant="primary"
              />
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: true,
                      planId,
                      formName
                    },
                    dispatch
                  );
                  this.props.history.push("/plans/" + planId);
                })}
                text="Submit"
                variant="primary"
              />
            </div>
          }
        >
          <FormSection header="Marketing">
            <ColumnLayout>
              <div data-awsui-column-layout-root="true">
                <PolarisSelectField
                  label="Do we have a marketing POC?"
                  name="hasMarketingPoc"
                  options={SELECT_OPTIONS}
                  placeholder="Please select an option."
                />
                <PolarisSelectField
                  label="Do we have a partner-led marketing plan for AWS in place?"
                  name="hasMarketingPlan"
                  options={SELECT_OPTIONS}
                  placeholder="Please select an option."
                />
                <PolarisTextAreaField
                  label="Rate partner's marketing effectiveness based on previous experience"
                  name="effectiveness"
                  placeholder="0 - 10"
                  validate={[wholeNumberValidation, zeroToTenValidation]}
                />
                <PolarisTextAreaField
                  label="What is your marketing budget for public cloud?"
                  name="marketingBudget"
                  placeholder="$"
                  validate={decimalNumberValidation}
                />
                <PolarisTextAreaField
                  label="What is your planned spend with AWS Partner Network (APN)?"
                  name="plannedSpend"
                  placeholder="$"
                  validate={decimalNumberValidation}
                />
                <PolarisTextAreaField
                  label="What is your AWS Marketplace budget?"
                  name="marketPlaceBudget"
                  placeholder="$"
                  validate={decimalNumberValidation}
                />
                <PolarisTextAreaField
                  label="Who is the primary marketing contact? Are they dedicated to AWS? "
                  name="primaryContact"
                  placeholder="Enter here..."
                />
                <PolarisTextAreaField
                  label="Do you have ISR/LDR/BDR resources available for lead follow-up and qualification for sales, and are they trained on this cloud solution on AWS?"
                  name="bdrResources"
                  placeholder="Enter here..."
                />
              </div>
            </ColumnLayout>
          </FormSection>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    fetchPlan: planId => dispatch(fetchPlan(planId)),
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Marketing);
const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
