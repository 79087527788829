import React, {Component} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import fetchUsername from './actions/user';
import {AuthComponent} from "views/components/AuthChecker";

import NavBar from './views/components/NavBar';
import PlansDashboard from './views/components/Dashboard/Plans/';
import PlanDashboard from './views/components/Dashboard/Plan';
import ImportPartnerPlan from './views/components/Dashboard/Import';
import CreatePartnerPlan from './views/components/Dashboard/Create';
import SectionRoute from './views/components/Dashboard/Plan/Sections/SectionRoute';

import sections from './config/sections';

import './App.css';
import {TokenToCookieComponent} from "views/components/TokenToCookie";
import {TOKEN_ENDPOINT} from "config/CognitoAuthOptions";

class App extends Component {
  componentDidMount() {
    this.props.fetchUsername();
  }

  render() {
    return (
    <div id="b">
        <Router>
          <React.Fragment>
            {/*This regex checks if the user is NOT asking for the token endpoint (e.g. /token) only create the Auth Component if the user is not on the token endpoint*/}
            <Route path={RegExp("^(?!.*(" + TOKEN_ENDPOINT + ")).*$")} component={AuthComponent}/>
            <NavBar />
            <Switch>
              <Route path={TOKEN_ENDPOINT} component={TokenToCookieComponent}/>
              <Route exact path="/" component={props => <PlansDashboard {...props} />} />
              <Route exact path="/plans/import" component={props => <ImportPartnerPlan {...props} />} />
              <Route exact path="/plans/create" component={props => <CreatePartnerPlan {...props} />} />
              {sections.map((section, index) => {
                const SectionComponent = section.component;
                return (
                  <SectionRoute
                    key={index}
                    path={`/plans/:planId/${section.module}`}
                    component={<SectionComponent />}
                  />
                );
              })}
              <Route path="/plans/:planId" component={props => <PlanDashboard {...props} />} />
            </Switch>
          </React.Fragment>
        </Router>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    fetchUsername: () => dispatch(fetchUsername())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(App);
