import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { ColumnLayout, Form, FormSection } from '@amzn/awsui-components-react/';

import WizardFooter from 'views/components/Wizard/Footer';
import PolarisInputField from 'views/components/Form/PolarisInputField';
import PolarisTextAreaField from 'views/components/Form/PolarisTextAreaField';

import { PARTNER_PLANNING } from 'constants/forms';
import { validateForm } from 'views/components/PartnerPlanModules/PartnerPlanning/validation';
import {percentageValidation} from "constants/forms/validation";
const FORM_NAME = PARTNER_PLANNING;

class PartnerBusinesModel extends Component {
  render() {
    const { activeStep, handleSubmit, nextStep, numberOfSteps, previousStep } = this.props;

    return (
      <Form
        onSubmit={handleSubmit}
        actions={
          <WizardFooter
            activeStep={activeStep}
            nextStep={nextStep}
            numberOfSteps={numberOfSteps}
            previousStep={previousStep}
            handleSubmit={handleSubmit}
            formName={FORM_NAME}
          />
        }
      >
        <FormSection
          header="Partner Business Model"
          description="Describe the structure of your partner’s businesses, headquarters, geos and or offices. Try to represent how they align with AWS offices."
        >
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <PolarisInputField
                label="Organization"
                InputField
                description="Maps To AWS"
                name="mapsToAWS"
                placeholder="Enter here..."
              />
              <PolarisInputField label="Consulting" name="consulting" placeholder="Enter here..." />
              {/*TODO: Boolean field below */}
              <PolarisInputField label="Managed Services" name="managedServices" placeholder="Enter here..." />
              <PolarisInputField label="Verticals" name="verticals" placeholder="Enter here..." />
              <PolarisInputField label="Public Sector (%)" name="publicSector" placeholder="Enter here..." validate={percentageValidation} />
              <PolarisInputField label="AWS Superpowers" name="awsSuperpowers" placeholder="Enter here..." />
            </div>
          </ColumnLayout>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <PolarisTextAreaField
                label="Describe the consulting business and solutions"
                name="describeBAS"
                placeholder="Enter here..."
              />
              <PolarisTextAreaField
                label="Describe managed services - Infra only? Specialty Managed Services?"
                name="describeMS"
                placeholder="Enter here..."
              />
              <PolarisTextAreaField
                label="Describe top verticals and what they offer."
                name="describeTopVerticals"
                placeholder="Enter here..."
              />
              <PolarisTextAreaField
                label="Describe the Public Sector presence- Fed, SLED, Education, Non-profit."
                name="describePS"
                placeholder="Enter here..."
              />
              <PolarisTextAreaField
                label="Describe AWS Superpowers. What sets them apart?"
                name="describeSuperpowers"
                placeholder="Enter here..."
              />
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validateForm
})(PartnerBusinesModel);

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
