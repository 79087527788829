import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { ColumnLayout, Form, FormSection } from '@amzn/awsui-components-react/';

import WizardFooter from 'views/components/Wizard/Footer';
import PolarisTextAreaField from 'views/components/Form/PolarisTextAreaField';

import { PARTNER_PLANNING } from 'constants/forms';
import { validateForm } from 'views/components/PartnerPlanModules/PartnerPlanning/validation';

const FORM_NAME = PARTNER_PLANNING;

class ExecutiveSummary extends Component {
  render() {
    const { activeStep, handleSubmit, nextStep, numberOfSteps, previousStep } = this.props;

    return (
      <Form
        onSubmit={handleSubmit}
        actions={
          <WizardFooter
            activeStep={activeStep}
            nextStep={nextStep}
            numberOfSteps={numberOfSteps}
            previousStep={previousStep}
            handleSubmit={handleSubmit}
            formName={FORM_NAME}
          />
        }
      >
        <FormSection header="Executive Summary">
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <PolarisTextAreaField
                label="Summary of the partner’s business, relationship with AWS, and plan for the year."
                name="executiveSummary"
                placeholder="Enter summary here..."
              />
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validateForm
})(ExecutiveSummary);

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
