import React, { Component } from 'react';
import {
  Button,
  Table,
  TableContentSelector,
  TableFiltering,
  TablePageSizeSelector,
  TablePreferences,
  TablePagination,
  TableSorting,
  TableWrapLines
} from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import fetchPlans from 'actions/plans';

import Header from './Header';

import Config from './config.js';

class PartnerTable extends Component {
  onFilteringChange = () => {
    //TODO: Make sure this is needed.
    return;
  };

  clearFilter = () => {
    //TODO: Make sure this is needed.
    return;
  };

  onWrapLinesChange = () => {
    //TODO: Make sure this is needed.
    return;
  };

  openCreatePage = () => {
    console.log('Open sesame.');
    this.props.history.push('/plans/create');
  };

  openImportPage = () => {
    this.props.history.push('/plans/import');
  };

  render() {
    const { plans, isLoading } = this.props;

    return (
      <React.Fragment>
        <Table
          loadingText="Loading your partner plans."
          loading={isLoading}
          columnDefinitions={Config.COLUMN_DEFINITIONS}
          items={plans}
          stickyHeader={true}
          resizableColumns={true}
          header={
            <Header openCreatePage={this.openCreatePage} openImportPage={this.openImportPage} counter={plans.length} />
          }
          noMatch={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-xs awsui-util-mb-xs">
                <b>No matches</b>
              </div>
              <p className="awsui-util-mb-s">We can't find a match.</p>
              <div className="awsui-util-mb-l">
                <Button onClick={this.clearFilter} text="Clear filter" />
              </div>
            </div>
          }
          wrapLines={false}
          onWrapLinesChange={this.onWrapLinesChange}
        >
          <TableFiltering
            filteringLabel="Filter Partner Plans"
            filteringPlaceholder="Find Partner Plan"
            filteringText={''}
            onFilteringChange={this.onFilteringChange}
          />
          <TableSorting sortableColumns={Config.SORTABLE_COLUMNS} />
          <TablePagination pageSize={10}></TablePagination>
          <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
            <TablePageSizeSelector title="Page size" options={Config.PAGE_SELECTOR_OPTIONS} />
            <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
            <TableContentSelector title="Select visible columns" options={Config.CONTENT_SELECTOR_OPTIONS} />
          </TablePreferences>
        </Table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { isLoading: state.plans.isLoading, plans: state.plans.plans };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPlans: () => dispatch(fetchPlans())
  };
};

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerTable);

export default withRouter(connectDecoratedComponent);
