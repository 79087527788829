import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {reduxForm} from 'redux-form';
import {ColumnLayout, Form, FormSection} from '@amzn/awsui-components-react/';

import WizardFooter from 'views/components/Wizard/Footer';
import PolarisInputField from 'views/components/Form/PolarisInputField';

import {PARTNER_PLANNING} from 'constants/forms';
import {validateForm} from 'views/components/PartnerPlanModules/PartnerPlanning/validation';
import {decimalNumberValidation} from "constants/forms/validation";

const FORM_NAME = PARTNER_PLANNING;

class APNResourceUtilization extends Component {
  render() {
    const { activeStep, handleSubmit, nextStep, numberOfSteps, previousStep, year } = this.props;

    return (
      <Form
        onSubmit={handleSubmit}
        actions={
          <WizardFooter
            activeStep={activeStep}
            nextStep={nextStep}
            numberOfSteps={numberOfSteps}
            previousStep={previousStep}
            handleSubmit={handleSubmit}
            formName={FORM_NAME}
          />
        }
      >
        <FormSection header="APN Resource Utilization">
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <PolarisInputField label="MDF" description={`${year - 1}`} name={`mdf${year - 1}`} placeholder="$" validate={decimalNumberValidation}/>
              <PolarisInputField label="POC" description={`${year - 1}`} name={`poc${year - 1}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 2}`} name={`mdf${year - 2}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 2}`} name={`poc${year - 2}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 3}`} name={`mdf${year - 3}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 3}`} name={`poc${year - 3}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField label="MAP" description={`${year - 1}`} name={`map${year - 1}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField
                label="AWS Promotional Credits"
                description={`${year - 1}`}
                name={`promoCredits${year - 1}`}
                placeholder="$" validate={decimalNumberValidation}
              />
              <PolarisInputField description={`${year - 2}`} name={`map${year - 2}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 2}`} name={`promoCredits${year - 2}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 3}`} name={`map${year - 3}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 3}`} name={`promoCredits${year - 3}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField label="ProServe Superchage" description={`${year - 1}`} name={`pss${year - 1}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField label="Training Funds" description={`${year - 1}`} name={`tf${year - 1}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 2}`} name={`pss${year - 2}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 2}`} name={`tf${year - 2}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 3}`} name={`pss${year - 3}`} placeholder="$" validate={decimalNumberValidation} />
              <PolarisInputField description={`${year - 3}`} name={`tf${year - 3}`} placeholder="$" validate={decimalNumberValidation} />
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    year: state.plan.plan.year,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validateForm
})(APNResourceUtilization);

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
