import axios from 'axios';
import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

import {SUBMIT_FORM} from 'constants/actionTypes/form';
import {submitFormFail, submitFormSuccess} from 'actions/form';
import {createErrorNotification, createSuccessNotification} from 'actions/notifications';
import {getIdentityFromCognitoCookie} from "constants/functions";

const submitFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_FORM),
    mergeMap(action => {
      const { formName, planId } = action.payload;
      delete action.payload['planId'];
      delete action.payload['formName'];
      const data = action.payload;
      console.log(data);
      return from(
        axios.post(`${state$.value.user.apiEndpoint}/plans/${planId}/${formName}`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getIdentityFromCognitoCookie()
          }
        })
      );
    }),
    mergeMap(response => {
      const formName = Object.keys(response.data.body.message)[0];
      const notificationId = response.data.body.message[formName].notificationId;
      const started = response.data.body.message[formName].started;
      const completed = response.data.body.message[formName].completed;
      const sections = state$.value.plan.plan.sections;
      let sectionsCompleted = 0;

      sections.map((section, index) => {
        if (section.module === formName) {
          section.started = started;
          section.completed = completed;
        }

        if (section.completed) {
          sectionsCompleted++;
        }

        return section;
      });
      const payload = {
        data: response.data.body.message,
        sections,
        sectionsCompleted
      };
      return [
        submitFormSuccess(payload),
        createSuccessNotification({
          notificationId,
          content: `Changes have been ${completed ? 'submitted' : 'saved'} successfully.`
        })
      ];
    }),
    catchError(err => {
      console.error(err);
      return of(
        submitFormFail(),
        createErrorNotification({
          notificationId: '',
          content: `An error has occured while trying to save.`
        })
      );
    })
  );

export default submitFormEpic;
