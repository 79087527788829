import axios from "axios";
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, map, mergeMap} from "rxjs/operators";
import sectionsMetadata from "config/sections";

import {FETCH_PLAN} from "constants/actionTypes/plan";
import {fetchPlanFail, fetchPlanSuccess} from "actions/plan/";
import {getIdentityFromCognitoCookie} from "constants/functions";
// https://ng52gunaic.execute-api.us-west-2.amazonaws.com/Prod/
const fetchPlansEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_PLAN),
    mergeMap(action => {
      return from(axios.get(`${state$.value.user.apiEndpoint}/plans/${action.payload.planId}`, {headers: {Authorization: getIdentityFromCognitoCookie()}}));
    }),
    map(response => {
      if (response.data.body && Object.keys(response.data.body).length !== 0) {
        const { planId, partnerName, year, forms, sectionsCompleted } = response.data.body;
        const dataOfSections = [];

        //TODO: Is there a better way to do this? Probably... we might want to rethink the data structure a bit....
        for (const section of sectionsMetadata) {
          let completed = forms[section.module] ? forms[section.module].completed : false;
          let started = forms[section.module] ? forms[section.module].started : false;
          const sectionData = {
            title: section.title,
            module: section.module,
            description: section.description,
            component: section.component,
            completed: completed,
            started: started
          };
          dataOfSections.push(sectionData);
        }
        return fetchPlanSuccess({
          plan: {
            id: planId,
            name: partnerName,
            year,
            sectionsCompleted,
            sections: dataOfSections,
            forms
          }
        });
      }
    }),
    catchError(err => {
      console.error(err);
      return of(fetchPlanFail());
    })
  );

export default fetchPlansEpic;
