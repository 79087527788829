import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { ColumnLayout, Form, FormSection } from '@amzn/awsui-components-react/';

import WizardFooter from 'views/components/Wizard/Footer';
import PolarisInputField from 'views/components/Form/PolarisInputField';

import { PARTNER_PLANNING } from 'constants/forms';
import { validateForm } from 'views/components/PartnerPlanModules/PartnerPlanning/validation';

const FORM_NAME = PARTNER_PLANNING;

class PartnershipDocuments extends Component {
  render() {
    const { activeStep, handleSubmit, nextStep, numberOfSteps, previousStep } = this.props;

    return (
      <Form
        onSubmit={handleSubmit}
        actions={
          <WizardFooter
            activeStep={activeStep}
            nextStep={nextStep}
            numberOfSteps={numberOfSteps}
            previousStep={previousStep}
            handleSubmit={handleSubmit}
            formName={FORM_NAME}
          />
        }
      >
        <FormSection header="Partnership Documents" description="Partnership Agreements in place.">
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <PolarisInputField label="AWS NDA" name="awsNda" placeholder="Enter here..." />
              <PolarisInputField
                label="AWS Reseller Agreement"
                name="awsResellerAgreement"
                placeholder="Enter here..."
              />
              <PolarisInputField label="AWS MSP Agreement" name="awsMspAgreement" placeholder="Enter here..." />
              <PolarisInputField label="AWS Services MSA" name="awsServicesMsa" placeholder="Enter here..." />
              <PolarisInputField
                label="AWS Marketplace Agreement"
                name="awsMarketplaceAgreement"
                placeholder="Enter here..."
              />
              <PolarisInputField label="AWS Support Level" name="awsSupportLevel" placeholder="Enter here..." />
              <PolarisInputField label="AWS Solutions Space" name="awsSolutionsSpace" placeholder="Enter here..." />
              <PolarisInputField label="AWS Practice Plan" name="awsPracticePlan" placeholder="Enter here..." />
              <PolarisInputField label="GTM Material" name="gtmMaterial" placeholder="Enter here..." />
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validateForm
})(PartnershipDocuments);

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
