export default function SalesAndTechnicalEnablement(report, year) {
  console.log("Started compiling Sales and Technical Enablement section")
  let tableBodyAWSBusinessTraining = [];

  //(report.awsBusinessTrainingQ1 || report.awsBusinessTrainingQ2 || report.awsBusinessTrainingQ3 || report.awsBusinessTrainingQ4) && tableBodyAWSBusinessTraining.push([{text: "Quarter 1", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 2", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 3", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 4", color: '#FFFFFF', fillColor: '#4a80bd'}]);
  //(report.awsBusinessTrainingQ1 || report.awsBusinessTrainingQ2 || report.awsBusinessTrainingQ3 || report.awsBusinessTrainingQ4) && tableBodyAWSBusinessTraining.push([report.awsBusinessTrainingQ1 ? report.awsBusinessTrainingQ1 : "", report.awsBusinessTrainingQ2 ? report.awsBusinessTrainingQ2 : "", report.awsBusinessTrainingQ3 ? report.awsBusinessTrainingQ3 : "", report.awsBusinessTrainingQ4 ? report.awsBusinessTrainingQ4 : ""]);

  let tableBodyAWSTechnicalTraining = [];

  //(report.awsTechnicalTrainingQ1 || report.awsTechnicalTrainingQ2 || report.awsTechnicalTrainingQ3 || report.awsTechnicalTrainingQ4) && tableBodyAWSTechnicalTraining.push([{text: "Quarter 1", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 2", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 3", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 4", color: '#FFFFFF', fillColor: '#4a80bd'}]);
  //(report.awsTechnicalTrainingQ1 || report.awsTechnicalTrainingQ2 || report.awsTechnicalTrainingQ3 || report.awsTechnicalTrainingQ4) && tableBodyAWSTechnicalTraining.push([report.awsTechnicalTrainingQ1 ? report.awsTechnicalTrainingQ1 : "", report.awsTechnicalTrainingQ2 ? report.awsTechnicalTrainingQ2 : "", report.awsTechnicalTrainingQ3 ? report.awsTechnicalTrainingQ3 : "", report.awsTechnicalTrainingQ4 ? report.awsTechnicalTrainingQ4 : ""]);

  let tableBodyAssociateCertifications = [];

  //(report.associateCertificationsQ1 || report.associateCertificationsQ2 || report.associateCertificationsQ3 || report.associateCertificationsQ4) && tableBodyAssociateCertifications.push([{text: "Quarter 1", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 2", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 3", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 4", color: '#FFFFFF', fillColor: '#4a80bd'}]);
  //(report.associateCertificationsQ1 || report.associateCertificationsQ2 || report.associateCertificationsQ3 || report.associateCertificationsQ4) && tableBodyAssociateCertifications.push([report.associateCertificationsQ1 ? report.associateCertificationsQ1 : "", report.associateCertificationsQ2 ? report.associateCertificationsQ2 : "", report.associateCertificationsQ3 ? report.associateCertificationsQ3 : "", report.associateCertificationsQ4 ? report.associateCertificationsQ4 : ""]);
  
  let tableBodyProfessionalCertifications = [];

  //(report.professionalCertificationsQ1 || report.professionalCertificationsQ2 || report.professionalCertificationsQ3 || report.professionalCertificationsQ4) && tableBodyProfessionalCertifications.push([{text: "Quarter 1", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 2", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 3", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 4", color: '#FFFFFF', fillColor: '#4a80bd'}]);
  //(report.professionalCertificationsQ1 || report.professionalCertificationsQ2 || report.professionalCertificationsQ3 || report.professionalCertificationsQ4) && tableBodyProfessionalCertifications.push([report.professionalCertificationsQ1 ? report.professionalCertificationsQ1 : "", report.professionalCertificationsQ2 ? report.professionalCertificationsQ2 : "", report.professionalCertificationsQ3 ? report.professionalCertificationsQ3 : "", report.professionalCertificationsQ4 ? report.professionalCertificationsQ4 : ""]);

  let tableBodyServiceDeliveryPrograms = [];

  //(report.serviceDeliveryProgramsQ1 || report.serviceDeliveryProgramsQ2 || report.serviceDeliveryProgramsQ3 || report.serviceDeliveryProgramsQ4) && tableBodyServiceDeliveryPrograms.push([{text: "Quarter 1", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 2", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 3", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 4", color: '#FFFFFF', fillColor: '#4a80bd'}]);
  //(report.serviceDeliveryProgramsQ1 || report.serviceDeliveryProgramsQ2 || report.serviceDeliveryProgramsQ3 || report.serviceDeliveryProgramsQ4) && tableBodyServiceDeliveryPrograms.push([report.serviceDeliveryProgramsQ1 ? report.serviceDeliveryProgramsQ1 : "", report.serviceDeliveryProgramsQ2 ? report.serviceDeliveryProgramsQ2 : "", report.serviceDeliveryProgramsQ3 ? report.serviceDeliveryProgramsQ3 : "", report.serviceDeliveryProgramsQ4 ? report.serviceDeliveryProgramsQ4 : ""]);

  let tableBodyCompetencyPrograms = [];

  //(report.competencyProgramsQ1 || report.competencyProgramsQ2 || report.competencyProgramsQ3 || report.competencyProgramsQ4) && tableBodyCompetencyPrograms.push([{text: "Quarter 1", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 2", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 3", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 4", color: '#FFFFFF', fillColor: '#4a80bd'}]);
  //(report.competencyProgramsQ1 || report.competencyProgramsQ2 || report.competencyProgramsQ3 || report.competencyProgramsQ4) && tableBodyCompetencyPrograms.push([report.competencyProgramsQ1 ? report.competencyProgramsQ1 : "", report.competencyProgramsQ2 ? report.competencyProgramsQ2 : "", report.competencyProgramsQ3 ? report.competencyProgramsQ3 : "", report.competencyProgramsQ4 ? report.competencyProgramsQ4 : ""]);

  let tableBodyOtherPrograms = [];

  //(report.otherProgramsQ1 || report.otherProgramsQ2 || report.otherProgramsQ3 || report.otherProgramsQ4) && tableBodyOtherPrograms.push([{text: "Quarter 1", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 2", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 3", color: '#FFFFFF', fillColor: '#4a80bd'}, { text: "Quarter 4", color: '#FFFFFF', fillColor: '#4a80bd'}]);
  //(report.otherProgramsQ1 || report.otherProgramsQ2 || report.otherProgramsQ3 || report.otherProgramsQ4) && tableBodyOtherPrograms.push([report.otherProgramsQ1 ? report.otherProgramsQ1 : "", report.otherProgramsQ2 ? report.otherProgramsQ2 : "", report.otherProgramsQ3 ? report.otherProgramsQ3 : "", report.otherProgramsQ4 ? report.otherProgramsQ4 : ""]);

  let salesAndTechnicalEnablement = report && report.completed 
      ? [
          {
            text: "Sales And Technical Enablement",
            style: "sectionHeader"
          },
          tableBodyAWSBusinessTraining.length || report.awsBusinessTrainingsGoal ? {
            text: "AWS Business Training",
            style: "sectionSubheader"
          } : {},
          report.awsBusinessTrainingsGoal ? { 
            text: [
              { 
                text: `${report.awsBusinessTrainingsGoal ? (year + " Goal: ") : ""}`,
                style: "sectionSubsubheader"
              },
              { 
                text: `${report.awsBusinessTrainingsGoal ? report.awsBusinessTrainingsGoal : ""}`,
                style: "sectionBody"
              }
            ]
          } : {},
          tableBodyAWSBusinessTraining.length ? {
            table: {
              widths: ['*', '*', '*', '*'],
              body: tableBodyAWSBusinessTraining
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
              }
            }
          } : {},
          tableBodyAWSTechnicalTraining.length || report.awsTechnicalTrainingsGoal ? {
            text: "AWS Technical Trainings",
            style: "sectionSubheader"
          } : {},
          report.awsTechnicalTrainingsGoal ? { 
            text: [
              { 
                text: `${report.awsTechnicalTrainingsGoal ? (year + " Goal: ") : ""}`,
                style: "sectionSubsubheader"
              },
              { 
                text: `${report.awsTechnicalTrainingsGoal ? report.awsTechnicalTrainingsGoal : ""}`,
                style: "sectionBody"
              }
            ]
          } : {},
          tableBodyAWSTechnicalTraining.length ? {
            table: {
              widths: ['*', '*', '*', '*'],
              body: tableBodyAWSTechnicalTraining
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
              }
            }
          } : {},
          tableBodyAssociateCertifications.length || report.associateCertificationsGoal ? {
            text: "Associate Certifications",
            style: "sectionSubheader"
          } : {},
          report.associateCertificationsGoal ? { 
            text: [
              { 
                text: `${report.associateCertificationsGoal ? (year + " Goal: ") : ""}`,
                style: "sectionSubsubheader"
              },
              { 
                text: `${report.associateCertificationsGoal ? report.associateCertificationsGoal : ""}`,
                style: "sectionBody"
              }
            ]
          } : {},
          tableBodyAssociateCertifications.length ? {
            table: {
              widths: ['*', '*', '*', '*'],
              body: tableBodyAssociateCertifications
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
              }
            }
          } : {},
          tableBodyProfessionalCertifications.length || report.professionalCertificationsGoal ? {
            text: "Professional Certifications",
            style: "sectionSubheader"
          } : {},
          report.professionalCertificationsGoal ? { 
            text: [
              { 
                text: `${report.professionalCertificationsGoal ? (year + " Goal: ") : ""}`,
                style: "sectionSubsubheader"
              },
              { 
                text: `${report.professionalCertificationsGoal ? report.professionalCertificationsGoal : ""}`,
                style: "sectionBody"
              }
            ]
          } : {},
          tableBodyProfessionalCertifications.length ? {
            table: {
              widths: ['*', '*', '*', '*'],
              body: tableBodyProfessionalCertifications
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
              }
            }
          } : {},
          tableBodyServiceDeliveryPrograms.length || report.serviceDeliveryProgramsGoal ? {
            text: "Service Delivery Programs",
            style: "sectionSubheader"
          } : {},
          report.serviceDeliveryProgramsGoal ? {
            text: [
              { 
                text: `${report.serviceDeliveryProgramsGoal ? (year + " Goal: ") : ""}`,
                style: "sectionSubsubheader"
              },
              { 
                text: `${report.serviceDeliveryProgramsGoal ? report.serviceDeliveryProgramsGoal : ""}`,
                style: "sectionBody"
              }
            ]
          } : {},
          tableBodyServiceDeliveryPrograms.length ? {
            table: {
              widths: ['*', '*', '*', '*'],
              body: tableBodyServiceDeliveryPrograms
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
              }
            }
          } : {},
          tableBodyCompetencyPrograms.length || report.competencyProgramsGoal ? {
            text: "Competency Programs",
            style: "sectionSubheader"
          } : {},
          report.competencyProgramsGoal ? { 
            text: [
              { 
                text: `${report.competencyProgramsGoal ? (year + " Goal: ") : ""}`,
                style: "sectionSubsubheader"
              },
              { 
                text: `${report.competencyProgramsGoal ? report.competencyProgramsGoal : ""}`,
                style: "sectionBody"
              }
            ]
          } : {},
          tableBodyCompetencyPrograms.length ? {
            table: {
              widths: ['*', '*', '*', '*'],
              body: tableBodyCompetencyPrograms
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
              }
            }
          } : {},
          tableBodyOtherPrograms.length || report.otherProgramsGoal ? {
            text: "Other Programs",
            style: "sectionSubheader"
          } : {},
          report.otherProgramsGoal ? { 
            text: [
              { 
                text: `${report.otherProgramsGoal ? (year + " Goal: ") : ""}`,
                style: "sectionSubsubheader"
              },
              { 
                text: `${report.otherProgramsGoal ? report.otherProgramsGoal : ""}`,
                style: "sectionBody"
              }
            ]
          } : {},
          tableBodyOtherPrograms.length ? {
            table: {
              widths: ['*', '*', '*', '*'],
              body: tableBodyOtherPrograms
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
              }
            }
          } : {},
          report.businessTrainingCourseName || report.businessTrainingCourseAttendee || report.businessTrainingCourseLocation || report.businessTrainingCourseQT ? {
            text: "Business Training Classes",
            style: "sectionSubheader"
          } : {},
          { 
            text: `${report.businessTrainingCourseName ? "Course Name:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.businessTrainingCourseName ? report.businessTrainingCourseName : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.businessTrainingCourseAttendee ? "Number of Attendees:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.businessTrainingCourseAttendee ? report.businessTrainingCourseAttendee : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.businessTrainingCourseLocation ? "Location:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.businessTrainingCourseLocation ? report.businessTrainingCourseLocation : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.businessTrainingCourseQT ? "QTR:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.businessTrainingCourseQT ? report.businessTrainingCourseQT : ""}`,
            style: "sectionBody"
          },


          report.technicalTrainingCourseName || report.technicalTrainingCourseAttendee || report.technicalTrainingCourseLocation || report.technicalTrainingCourseQT ? {
            text: "Technical Training Classes",
            style: "sectionSubheader"
          } : {},
          { 
            text: `${report.technicalTrainingCourseName ? "Course Name:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.technicalTrainingCourseName ? report.technicalTrainingCourseName : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.technicalTrainingCourseAttendee ? "Number of Attendees:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.technicalTrainingCourseAttendee ? report.technicalTrainingCourseAttendee : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.technicalTrainingCourseLocation ? "Location:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.technicalTrainingCourseLocation ? report.technicalTrainingCourseLocation : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.technicalTrainingCourseQT ? "QTR:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.technicalTrainingCourseQT ? report.technicalTrainingCourseQT : ""}`,
            style: "sectionBody"
          },


          report.programObtainmentName || report.programObtainmentSpecificProgram || report.programObtainmentQTR ? {
            text: "Program Obtainment",
            style: "sectionSubheader"
          } : {},
          { 
            text: `${report.programObtainmentName ? "Program Name:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.programObtainmentName ? report.programObtainmentName : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.programObtainmentSpecificProgram ? "Specific Program:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.programObtainmentSpecificProgram ? report.programObtainmentSpecificProgram : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.programObtainmentQTR ? "QTR:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.programObtainmentQTR ? report.programObtainmentQTR : ""}`,
            style: "sectionBody"
          },


          report.tierAdvancementName || report.tierAdvancementGaps || report.tierAdvancementQTR ? {
            text: "Tier Advancement",
            style: "sectionSubheader"
          } : {},
          { 
            text: `${report.tierAdvancementName ? "Tier Name:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.tierAdvancementName ? report.tierAdvancementName : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.tierAdvancementGaps ? "Gaps:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.tierAdvancementGaps ? report.tierAdvancementGaps : ""}`,
            style: "sectionBody"
          },
          { 
            text: `${report.tierAdvancementQTR ? "QTR:" : ""}`,
            style: "sectionSubsubheader" 
          },
          { 
            text: `${report.tierAdvancementQTR ? report.tierAdvancementQTR : ""}`,
            style: "sectionBody"
          },
        ]
      : null;
  console.log("Finished compiling Sales and Technical Enablement section")
  return salesAndTechnicalEnablement;
}
