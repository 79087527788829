import axios from 'axios';
import {ofType} from 'redux-observable';
import {from, of, timer} from 'rxjs';
import {catchError, delayWhen, map, mergeMap, retryWhen, tap} from 'rxjs/operators';

import {FETCH_PLANS} from 'constants/actionTypes/plans';
import {fetchPlansFail, fetchPlansSuccess} from 'actions/plans/';
import {getIdentityFromCognitoCookie, handleIfUnauthenticated} from "constants/functions";

const fetchPlansEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_PLANS),
    mergeMap(action => {
      return from(
        axios.get(`${state$.value.user.apiEndpoint}/plans`, {
          params: {
            userId: state$.value.user.username
          },
          headers: {Authorization: getIdentityFromCognitoCookie()}
        })
      );
    }),
    map(response => {
      handleIfUnauthenticated(response);
      return fetchPlansSuccess(response.data);
    }),
    retryWhen(errors =>
      errors.pipe(
        //log error message
        tap(err => console.error(err)),
        //restart in 6 seconds
        delayWhen(err => timer(10 * 1000))
      )
    ),
    catchError(err => {
      handleIfUnauthenticated(err);
      console.error(err);
      return of(fetchPlansFail());
    })
  );

export default fetchPlansEpic;
