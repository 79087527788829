import axios from 'axios';
import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import sectionsMetadata from 'config/sections';

import {GENERATE_PLAN} from 'constants/actionTypes/plan/generate';
import {generatePlanFail, generatePlanSuccess} from 'actions/plan/generate';
import makePdf from 'views/components/pdf/makePdf.js';
import {getIdentityFromCognitoCookie} from "constants/functions";

// https://ng52gunaic.execute-api.us-west-2.amazonaws.com/Prod/
const generatePlansEpic = (action$, state$) =>
  action$.pipe(
    ofType(GENERATE_PLAN),
    mergeMap(action => {
      return from(axios.get(`${state$.value.user.apiEndpoint}/plans/${action.payload.planId}`, {headers: {Authorization: getIdentityFromCognitoCookie()}}));
    }),
    mergeMap(response => {
      if (response.data.body && Object.keys(response.data.body).length !== 0) {
        const { planId, partnerName, year, forms, sectionsCompleted } = response.data.body;
        const dataOfSections = [];

        //TODO: Is there a better way to do this? Probably... we might want to rethink the data structure a bit....
        for (const section of sectionsMetadata) {
          let completed = forms[section.module] ? forms[section.module].completed : false;
          let started = forms[section.module] ? forms[section.module].started : false;
          const sectionData = {
            title: section.title,
            module: section.module,
            description: section.description,
            component: section.component,
            completed: completed,
            started: started
          };
          dataOfSections.push(sectionData);
        }
        return [
          generatePlanSuccess({
            plan: {
              id: planId,
              name: partnerName,
              year,
              sectionsCompleted,
              sections: dataOfSections,
              forms
            }
          }),
          makePdf(forms, year, partnerName, state$.value.user.apiEndpoint)
        ];
      }
    }),
    catchError(err => {
      console.error(err);
      return of(generatePlanFail());
    })
  );

export default generatePlansEpic;
