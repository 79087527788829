export default function CoSelling(report) {
  console.log("Started compiling CoSelling section")
  let coSelling =
    report && report.completed
      ? [
        {
            text: "Co-Selling",
            style: "sectionHeader"
        },
        [
          {text: `${report.sellerIncentive ? "Are sellers comped for AWS? Are their incentives neutral, AWS positive or AWS negative?" : ""}`, style: "sectionSubheader"},
          {text: `${report.sellerIncentive ? report.sellerIncentive : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${report.competitiveRelationship ? "What is their relationship with Microsoft Azure and Legacy businesses?" : ""}`, style: "sectionSubheader"},
          {text: `${report.competitiveRelationship ? report.competitiveRelationship : ""}`, style: "sectionBody"}
        ], 
        [
          {text: `${report.publicCloudRelationship ? "Are there other public cloud businesses which your sellers are focused on?" : ""}`, style: "sectionSubheader"},
          {text: `${report.publicCloudRelationship ? report.publicCloudRelationship : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${report.engagedCoSellingEffots ? "Is partner engaged with co-selling efforts with AWS today? In what form?" : ""}`, style: "sectionSubheader"},
          {text: `${report.engagedCoSellingEffots ? report.engagedCoSellingEffots : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${report.newSolutionDevelopment ? "Does partner have new solution under development in which they would like co-selling support?" : ""}`, style: "sectionSubheader"},
          {text: `${report.newSolutionDevelopment ? report.newSolutionDevelopment : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${report.solutionDriveAWSSalesGoals ? "How does the partner solution drive AWS sales goals (help meet quota or SPIF)?" : ""}`, style: "sectionSubheader"},
          {text: `${report.solutionDriveAWSSalesGoals ? report.solutionDriveAWSSalesGoals : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${report.successfulUseCases ? "Most successful use-cases that have been successful from a GTM perspective:" : ""}`, style: "sectionSubheader"},
          {text: `${report.successfulUseCases ? report.successfulUseCases : ""}`, style: "sectionBody"}
        ]
      ]
      : null;
  console.log("Finished compiling CoSelling section")

  return coSelling;
}
