export const validateForm = values => {
  console.log(values);

  const errors = {};

  if (!values.reportName) {
    errors.reportName = 'Business Plan Name is required.';
  }

  if (!values.year) {
    errors.year = 'Year is required.';
  }

  const year = new Date().getFullYear();
  if (values.year < 2000 && values.year > year + 20) {
    errors.year = 'Please enter a valid year.';
  }

  return errors;
};
