import React from "react";
import { Field } from "redux-form";
import { Textarea, FormField } from "@amzn/awsui-components-react";

const renderField = ({
  input: { onChange, onBlur, value },
  name,
  label,
  rows,
  placeholder,
  description,
  meta: { touched, error, warning, pristine },
  autofocus = false,
  stretch
}) => {
  const onInput = event => {
    onChange(event.detail.value);
  };

  const myBlur = () => {
    onBlur();
  };

  return (
    <FormField
      className="awsui-util-d-b awsui-util-mb-m"
      description={description}
      stretch={stretch}
      errorText={!pristine && touched && error}
      label={label}
    >
      <Textarea
        onInput={onInput}
        onChange={onChange}
        onBlur={myBlur}
        placeholder={placeholder}
        rows={rows}
        autofocus={autofocus}
        name={name}
        value={value}
      />
    </FormField>
  );
};

const PolarisTextAreaField = ({
  label,
  name,
  description,
  placeholder,
  autofocus = false,
  stretch = true,
  rows = 3,
  validate
}) => {
  return (
    <Field
      label={label}
      name={name}
      description={description}
      component={renderField}
      autofocus={autofocus}
      placeholder={placeholder}
      stretch={stretch}
      type="textarea"
      validate={validate}
    />
  );
};

export default PolarisTextAreaField;
