import { IMPORT_PLAN, IMPORT_PLAN_SUCCESS, IMPORT_PLAN_FAIL } from "constants/actionTypes/plan/import";

const importPlan = payload => {
  return { type: IMPORT_PLAN, payload };
};

export const importPlanSuccess = payload => {
  return { type: IMPORT_PLAN_SUCCESS, isLoading: false, payload };
};

export const importPlanFail = payload => {
  return { type: IMPORT_PLAN_FAIL, payload };
};

export default importPlan;
