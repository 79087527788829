import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import configSections from 'config/sections';
import './style.css';
import { Icon } from '@amzn/awsui-components-react';

const NavigationDrawer = ({ id, sectionsCompleted, sections }) => {
  const calculatedPercentage = sectionsCompleted / sections.length;
  return (
    <div className="plan-progress-drawer">
      <div className="plan-progress-tracker">
        <h3>Business Plan Sections</h3>
        <div className="awsui-util-font-size-0 plan-progress-tracker-subtitle">
          Your business plan is {Math.round(calculatedPercentage * 100)}% complete!
        </div>
      </div>
      <div className="plan-progress-navigation">
        {configSections.map((configSection, index) => {
          const [mappedSection] = sections.filter(section => {
            return section.module === configSection.module;
          });
          return (
            <Link key={index} to={`/plans/${id}/${configSection.module}`} className="plan-progress-navigation-link">
              <div
                variant="link"
                className={`plan-progress-navigation-section ${
                  mappedSection && mappedSection.completed ? 'plan-progress-navigation-section-completed' : ''
                }`}
              >
                <div>{configSection.title}</div>
                {mappedSection && mappedSection.completed ? <Icon variant="success" name="status-positive" /> : ''}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    sectionsCompleted: state.plan.plan.sectionsCompleted,
    sections: state.plan.plan.sections
  };
};

export default connect(mapStateToProps)(NavigationDrawer);
