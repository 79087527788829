import ExecutiveSummary from './ExecutiveSummary';
import APNResourceUtilization from './APNResourceUtilization';
import TopInitiatives from './TopInitiatives';
import PartnerBusinessModel from './PartnerBusinessModel';
import RevenueBreakdown from './RevenueBreakdown';
import PartnershipDocuments from './PartnershipDocuments';
import StrengthsAndChallenges from './StrengthsAndChallenges';

export const PARTNER_PLANNING_STEPS = [
  {
    title: 'Executive Summary',
    component: ExecutiveSummary
  },
  {
    title: 'APN Resource Utilization',
    component: APNResourceUtilization
  },
  {
    title: 'Top Initiatives',
    component: TopInitiatives
  },
  {
    title: 'Partner Business Model',
    component: PartnerBusinessModel
  },
  {
    title: 'Revenue Breakdown',
    component: RevenueBreakdown
  },
  {
    title: 'Partnership Document',
    component: PartnershipDocuments
  },
  {
    title: 'Strengths And Challenges',
    component: StrengthsAndChallenges
  }
];
