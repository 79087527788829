export const NOT_A_VALID_NUMBER =
  "Please enter a valid number. ($ and commas not allowed)";
const isEmpty = str => {
    return (!str || str.toString().trim().length === 0);
};
const isWebsite = str => {
    //regex checking websites is hard. https://stackoverflow.com/a/50037511.
    // this simple regex just makes sure the user tried: something.something
    const pat = new RegExp('^([^\\s]+\\.[^\\s]+)+$');
    return pat.test(str.toString());
};
const isWholeNumber = str => {
    const decRegex = new RegExp('^\\d+$');
    return Number.isInteger(Number.parseFloat(str)) && decRegex.test(str);
};
// const isAlphanumeric = str => {
//     //TODO: support global characters, an involved process but we do have partner names in other languages....
//     // https://stackoverflow.com/a/22075070  & http://xregexp.com/plugins/ <-- starting point, but its not all...
//     const alphanumRegex = new RegExp('[^A-Za-z0-9\\s]');
//     return !alphanumRegex.test(str.toString()); //negated because regex is matching everything BUT.
// };
// const isAlphabetic = str => {
//     //todo support global characters. see above.
//     const alphanumRegex = new RegExp('[^A-Za-z\\s]');
//     return !alphanumRegex.test(str.toString()); //negated because regex is matching everything BUT.
//
// };
const isPercentage = num => {
    return  !isNaN(num) && (num >= 0) && (num <= 100);
};

const isNames = str => {
    const nameListRegex = new RegExp('^[a-zA-Z\', .-]+$');
    return nameListRegex.test(str);
};

//These all return a string or undefined because react-forms validation accepts undefined as "good".
export const requiredValidation = value => {
    return !value ? 'This field is required.' : undefined;
};
export const decimalNumberValidation = value => {
    return !isEmpty(value) && isNaN(value) ? 'This field requires a number.' : undefined;
};
export const wholeNumberValidation = value => {
    return !isEmpty(value) && !isWholeNumber(value) ? 'This field requires a whole number. (No commas)' : undefined;
};
export const websiteValidation = value => {
    return (!isEmpty(value) && !isWebsite(value.toString().trim())) ? 'This field requires a valid website.' : undefined;
};
// export const alphanumericValidation = value => {
//     return (!isEmpty(value) && !isAlphanumeric(value)) ? 'Please use letters and numbers only.' : undefined;
// };
// export const alphabeticValidation = value => {
//     return (!isEmpty(value) && !isAlphabetic(value)) ? 'Please only use letters.' : undefined;
// };
export const nameListValidation = value => {
    return (!isEmpty(value) && !isNames(value)) ? 'Please only input names.' : undefined;
};
export const percentageValidation = value => {
    return (!isEmpty(value) && !isPercentage(value)) ? 'Please enter a valid percentage. (0 to 100)' : undefined;
};
export const zeroToTenValidation = value => {
    return (!isEmpty(value) && (value < 0 || value > 10)) ? 'Please enter a value from 0 to 10.' : undefined;
};
export const positiveNumberValidation = value => {
    return (!isEmpty(value) && ( value < 0)) ? 'Please enter a non-negative number.' : undefined;
};