import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { ColumnLayout, Form, FormSection } from '@amzn/awsui-components-react/';

import WizardFooter from 'views/components/Wizard/Footer';
import PolarisTextAreaField from 'views/components/Form/PolarisTextAreaField';

import { PARTNER_PLANNING } from 'constants/forms';
import { validateForm } from 'views/components/PartnerPlanModules/PartnerPlanning/validation';

const FORM_NAME = PARTNER_PLANNING;

class StrengthsAndChallenges extends Component {
  render() {
    const { activeStep, handleSubmit, nextStep, numberOfSteps, previousStep } = this.props;

    return (
      <Form
        onSubmit={handleSubmit}
        actions={
          <WizardFooter
            activeStep={activeStep}
            nextStep={nextStep}
            numberOfSteps={numberOfSteps}
            previousStep={previousStep}
            handleSubmit={handleSubmit}
            formName={FORM_NAME}
          />
        }
      >
        <FormSection
          header="Strengths and Challenges"
          description="Use a SWOT to help define your partner's strengths and areas of improvement. These could be organizational around the alliance resources or alignment, enablement, leadership, focus, delivery customer satisfaction, etc."
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <PolarisTextAreaField label="Stengths" name="sacStrengths" placeholder="Enter here..." />
              <PolarisTextAreaField label="Weakness" name="sacWeakness" placeholder="Enter here..." />
              <PolarisTextAreaField label="Opportunities" name="sacOpportunities" placeholder="Enter here..." />
              <PolarisTextAreaField label="Threats" name="sacThreat" placeholder="Enter here..." />
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validateForm
})(StrengthsAndChallenges);

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
