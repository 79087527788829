import { createSelector } from "reselect";
import { ACTIVE } from "constants/notifications";
const notifications = ({ user }) => user.notifications;

export const activeNotificationSelector = createSelector(
  notifications,
  notificationsCollection => {
    const filteredNotifications = [];
    Object.keys(notificationsCollection)
      .filter(key => {
        return notificationsCollection[key]["status"] === ACTIVE;
      })
      .map(key => {
        const notification = notificationsCollection[key];
        notification["id"] = key;
        filteredNotifications.push(notification);
        return notification;
      });
    return filteredNotifications;
    // return notificationsCollection.filter(notification => notification.status === "active");
  }
);
