import {of} from "rxjs";
import {fetchUsernameFail, fetchUsernameSuccess} from "actions/user";
import {getAliasFromCognitoCookie} from "constants/functions";

const fetchUserEpic = () => {
  let username = getAliasFromCognitoCookie();
  if (username == null) {
    return of(fetchUsernameFail());
  } else {
      return of(fetchUsernameSuccess(username));
  }
};

export default fetchUserEpic;
