import { FETCH_PLAN_SUCCESS } from 'constants/actionTypes/plan';
import { SUBMIT_FORM_SUCCESS } from 'constants/actionTypes/form';

const initialState = {
  isLoading: true,
  plan: {
    id: '338ba1f0-3c50-11e9-b891-1d675575973c',
    name: 'HashiCorp',
    year: 2018,
    sectionsCompleted: 2,
    sections: [
      {
        PartnerMetrics: {
          started: true,
          completed: true
        },
        PartnerPlanning: {
          started: true,
          completed: true
        },
        PartnerOpportunities: {
          started: true,
          completed: true
        }
      }
    ],
    forms: {
      PartnerMetrics: {}
    }
  }
};

const plan = (state = initialState, action) => {
  if (action.type === FETCH_PLAN_SUCCESS) {
    const plan = action.payload.plan;
    return {
      ...state,
      isLoading: action.isLoading,
      plan
    };
  }

  if (action.type === SUBMIT_FORM_SUCCESS) {
    return {
      ...state,
      isLoading: action.isLoading,
      plan: {
        ...state.plan,
        sectionsCompleted: action.payload.sectionsCompleted,
        sections: action.payload.sections,
        forms: {
          ...action.payload.data
        }
      }
    };
  }
  return state;
};

export default plan;
