export default function PartnerMetrics(report, year) {
  console.log("Started compiling Partner Metrics section")

  // Basically all of these next two big sections is building the tables one piece at a time, because we don't know how many pieces are in the table until we check each one manually.

  let tableBodyPartnerInfo = [];
  report.companyWebsite && tableBodyPartnerInfo.push([{text: `${report.companyWebsite ? "Company Website:" : ""}`, color: '#FFFFFF'}, `${report.companyWebsite ? report.companyWebsite : ""}`]);//[{text: 'Column 1', color: '#FF0000', fillColor: '#00FF00'}, 'Column 2'])//
  report.companyRevenue && tableBodyPartnerInfo.push([{text: `${report.companyRevenue ? "Company Revenue:" : ""}`, color: '#FFFFFF'}, `${report.companyRevenue ? report.companyRevenue : ""}`]);
  report.officeLocation && tableBodyPartnerInfo.push([{text: `${report.officeLocation ? "Office Location:" : ""}`, color: '#FFFFFF'}, `${report.officeLocation ? report.officeLocation : ""}`]);
  report.employeeCount && tableBodyPartnerInfo.push([{text: `${report.employeeCount ? "Number of Employees by Geo/Office:" : ""}`, color: '#FFFFFF'}, `${report.employeeCount ? report.employeeCount : ""}`]);
  report.salesStakeholderCount && tableBodyPartnerInfo.push([{text: `${report.salesStakeholderCount ? "Number of Sales Stakeholders:" : ""}`, color: '#FFFFFF'}, `${report.salesStakeholderCount ? report.salesStakeholderCount : ""}`]);
  report.technicalStakeholdersCount && tableBodyPartnerInfo.push([{text: `${report.technicalStakeholdersCount ? "Number of Technical Stakeholders:" : ""}`, color: '#FFFFFF'}, `${report.technicalStakeholdersCount ? report.technicalStakeholdersCount : ""}`]);
  report.marketingStakeholdersCount && tableBodyPartnerInfo.push([{text: `${report.marketingStakeholdersCount ? "Number of Marketing Stakeholders:" : ""}`, color: '#FFFFFF'}, `${report.marketingStakeholdersCount ? report.marketingStakeholdersCount : ""}`]);
  report.partnerTier && tableBodyPartnerInfo.push([{text: `${report.partnerTier ? "APN Partner Tier:" : ""}`, color: '#FFFFFF'}, `${report.partnerTier ? report.partnerTier : ""}`]);
  report.keyPartners && tableBodyPartnerInfo.push([{text: `${report.keyPartners ? "Key Partners:" : ""}`, color: '#FFFFFF'}, `${report.keyPartners ? report.keyPartners : ""}`]);
  report.apnExecutiveSponsors && tableBodyPartnerInfo.push([{text: `${report.apnExecutiveSponsors ? "APN Executive Sponsors:" : ""}`, color: '#FFFFFF'}, `${report.apnExecutiveSponsors ? report.apnExecutiveSponsors : ""}`]);
  report.currentApnPrograms && tableBodyPartnerInfo.push([{text: `${report.currentApnPrograms ? "Current APN Programs:" : ""}`, color: '#FFFFFF'}, `${report.currentApnPrograms ? report.currentApnPrograms : ""}`]);
  report.competitivePresense && tableBodyPartnerInfo.push([{text: `${report.competitivePresense ? "Competitive Presense (MS, GCP...):" : ""}`, color: '#FFFFFF'}, `${report.competitivePresense ? report.competitivePresense : ""}`]);

  let tableBodyPartnerMetrics = [];

  (report[(year-0)+"PartnerRevenue"] || report[(year-0)+"revenueChangeInfluenced"] || report[(year-1)+"PartnerRevenue"] || report[(year-1)+"revenueChangeInfluenced"] ||report[(year-2)+"PartnerRevenue"] || report[(year-2)+"revenueChangeInfluenced"]) && tableBodyPartnerMetrics.push([{text: "Year:", color: '#FFFFFF', fillColor: '#4a80bd'}, {text: "Direct Partner Revenue:", color: '#FFFFFF', fillColor: '#4a80bd'}, {text: "Direct Partner Revenue YoY% Change:", color: '#FFFFFF', fillColor: '#4a80bd'}]);
  (report[(year-0)+"PartnerRevenue"] || report[(year-0)+"revenueChangeInfluenced"]) && tableBodyPartnerMetrics.push([{text: (year-0), color: '#FFFFFF'}, report[(year-0)+"PartnerRevenue"] ? report[(year-0)+"PartnerRevenue"] : "", report[(year-0)+"revenueChangeInfluenced"] ? report[(year-0)+"revenueChangeInfluenced"] : ""]);
  (report[(year-1)+"PartnerRevenue"] || report[(year-1)+"revenueChangeInfluenced"]) && tableBodyPartnerMetrics.push([{text: (year-1), color: '#FFFFFF'}, report[(year-1)+"PartnerRevenue"] ? report[(year-1)+"PartnerRevenue"] : "", report[(year-1)+"revenueChangeInfluenced"] ? report[(year-1)+"revenueChangeInfluenced"] : ""]);
  (report[(year-2)+"PartnerRevenue"] || report[(year-2)+"revenueChangeInfluenced"]) && tableBodyPartnerMetrics.push([{text: (year-2), color: '#FFFFFF'}, report[(year-2)+"PartnerRevenue"] ? report[(year-2)+"PartnerRevenue"] : "", report[(year-2)+"revenueChangeInfluenced"] ? report[(year-2)+"revenueChangeInfluenced"] : ""]);

  
  let partnerMetrics =
    report && report.completed
      ? [
          tableBodyPartnerInfo.length !== 0 ? { //Make sure we actually have info for this section
            text: "Partner Info",
            style: "sectionHeader"
          } : null,

          tableBodyPartnerInfo.length !== 0 ? { //Make sure we have the section
            table: {
              margin: [0,5,0,0],
              widths: ['auto', '*'],
              body: tableBodyPartnerInfo
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                if(columnIndex === 0){
                  return '#4a80bd';
                }
                return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
              }
              //For whatever reason, you cannot set text color and fillColor or the text becomes invisible
            }
          } : {},
          tableBodyPartnerMetrics.length !== 0 ? { //Same deal, making sure we have the info for the section
            text: "Partner Metrics",
            style: "sectionHeader"
          } : null,
          tableBodyPartnerMetrics.length !== 0 ? { //Making sure we have the section
            table: {
              margin: [0,5,0,0],
              widths: ['auto', "*", "*"],
              body: tableBodyPartnerMetrics
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                if(columnIndex === 0){
                  return '#4a80bd';
                }
                return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
              }
            }
          } : null,
        ]
      : null;
      console.log("Finished compiling Partner Metrics section")
  return partnerMetrics;
}
