import React from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react/';

import PolarisInputField from 'views/components/Form/PolarisInputField';
import PolarisTextAreaField from 'views/components/Form/PolarisTextAreaField';

const Goal = (partnerGoal, index) => {
  return (
    <div key={index}>
      <h2 style={{ marginBottom: 10 }}>Goal #{index + 1}</h2>
      <ColumnLayout>
        <div data-awsui-column-layout-root="true">
          <PolarisInputField
            label="Goal Name"
            name={`${partnerGoal}.partnerGoalGoalName`}
            required
            autofocus
            placeholder="Enter here..."
          />
          <PolarisTextAreaField
            label="Goal Description"
            name={`${partnerGoal}.partnerGoalGoalDescription`}
            required
            autofocus
            placeholder="Enter here..."
          />
        </div>
      </ColumnLayout>
      <ColumnLayout columns={2}>
        <div data-awsui-column-layout-root="true">
          <PolarisInputField
            label="Success"
            name={`${partnerGoal}.partnerGoalSuccess`}
            required
            autofocus
            placeholder="Enter here..."
          />
          <PolarisInputField
            label="Time"
            name={`${partnerGoal}.partnerGoalTime`}
            required
            autofocus
            placeholder="Enter here..."
          />
        </div>
      </ColumnLayout>
    </div>
  );
};

export default Goal;
