import React from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react/';

import PolarisInputField from 'views/components/Form/PolarisInputField';
import PolarisTextAreaField from 'views/components/Form/PolarisTextAreaField';
import {decimalNumberValidation} from "constants/forms/validation";

const Initiative = (iniative, index) => {
  console.log('Index');
  console.log(index);
  return (
    <div key={index}>
      <h2 style={{ marginBottom: 10 }}>Initiative #{index + 1}</h2>
      <ColumnLayout>
        <div data-awsui-column-layout-root="true">
          <PolarisInputField label="Goal" name={`${iniative}.goal`} placeholder="Enter here..." />
          <PolarisTextAreaField label="Description" name={`${iniative}.goalDescription`} placeholder="Enter here..." />
        </div>
      </ColumnLayout>
      <ColumnLayout columns={2}>
        <div data-awsui-column-layout-root="true">
          <PolarisInputField label="Task to Complete" name={`${iniative}.taskToComplete`} placeholder="Enter here..." />
          <PolarisInputField label="Partner" name={`${iniative}.partner`} placeholder="Enter here..." />
          <PolarisInputField label="Metrics" name={`${iniative}.metrics`} placeholder="Enter here..." validate={decimalNumberValidation} />
        </div>
      </ColumnLayout>
      <hr />
    </div>
  );
};

export default Initiative;
