import { FETCH_USERNAME, FETCH_USERNAME_SUCCESS, FETCH_USERNAME_FAIL } from "constants/actionTypes/user";

const fetchUsername = () => ({ type: FETCH_USERNAME, isLoading: true });

export const fetchUsernameSuccess = username => {
  return { type: FETCH_USERNAME_SUCCESS, isLoading: false, payload: { username } };
};

export const fetchUsernameFail = () => {
  return { type: FETCH_USERNAME_FAIL, isLoading: false };
};

export default fetchUsername;
