import axios from "axios";
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, mergeMap} from "rxjs/operators";

import {CREATE_PLAN} from "constants/actionTypes/plan/create";

import fetchPlans from "actions/plans";
import {createPlanFail, createPlanSuccess} from "actions/plan/create";
import {createErrorNotification, createSuccessNotification} from "actions/notifications";
import {getIdentityFromCognitoCookie} from "constants/functions";

const createPlanEpic = (action$, state$) => {
  return action$.pipe(
    ofType(CREATE_PLAN),
    mergeMap(action => {
      if (state$.value.plans.plans.filter(plan => (plan.partnerName === action.payload.reportName) && (plan.year === action.payload.year)).length) {
        console.error("Report already exists!")
        throw new Error("Report Already Exists");
      } 
      else {
        let data = {
          reportName: action.payload.reportName,
          year: action.payload.year,
          notificationId: action.payload.notificationId,
          userId: state$.value.user.username
        };
        console.log("Making new Report")
        return from(axios.post(`${state$.value.user.apiEndpoint}/plan`, data, {headers: {Authorization: getIdentityFromCognitoCookie()}})); //TODO: We need to change this endpoint
      }
    }),
    mergeMap(response => {
      if(response.data.body.error){
        throw new Error(response.data.body.error)
      }
      return [
        createPlanSuccess(response.data.body.message),
        createSuccessNotification({
          notificationId: response.data.body.message.notificationId,
          content: `Creating a new action plan complete.`
        }),
        fetchPlans()
      ];
    }),
    catchError(err => {
      console.log("Received Server Error")
      console.error(err);
      return of(
        createPlanFail(),
        createErrorNotification({
          notificationId: "",
          content: `Creating a new action plan failed. ${err ? err : null}`
        })
      );
    })
  );
};

export default createPlanEpic;
