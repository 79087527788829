import React from "react";
import {withRouter} from 'react-router-dom';
import {getNewCognitoToken, setCognitoCookie} from "constants/functions";
import {Spinner} from '@amzn/awsui-components-react';


export const TokenToCookieComponent = withRouter(props => <TokenToCookie {...props}/>);

class TokenToCookie extends React.Component {

  componentDidMount() {
    let hash = document.location.hash;

    //if someone hits /token without a token, redirect to base URL
    if (hash.length === 0) {
      window.location.replace(document.location.origin);
    }

    //we need the identity token, check if in url
    if (hash.includes('id_token')) {
      //parse the url token
      hash = hash.substring(1); //remove hash (#)

      let id_token = {};
      let expires_in = {};

      //pull out the Identity JWT token
      hash.split("&").forEach((item) => {
        let keyValuePair = item.split('=');
        if (keyValuePair[0] === 'id_token') {
          id_token = keyValuePair[1];
        } else if (keyValuePair[0] === 'expires_in')
          expires_in = keyValuePair[1];
      });

      if (!id_token) {
        getNewCognitoToken(); //go grab a new token. if we couldnt parse identity
      }

      //get expiring time from keyvalues (or assume 1 hour)
      expires_in = (expires_in) ? parseInt(expires_in) * 1000 : 3600 * 1000;
      let expireDate = new Date();
      expireDate.setTime((new Date().getTime() + expires_in));
      setCognitoCookie(id_token, expireDate.toUTCString());

      //redirect to base url. (we dont use router because we want a reload of the app to start the auth component)
      window.location.replace(document.location.origin);

    } else { //go grab a new token. somethings wrong.
      getNewCognitoToken();
    }
  }

  render() {
    return (
      <div aria-label="Authenticating...">
        <Spinner size="large"/>
      </div>
    )
  }

}