import { FETCH_PLAN, FETCH_PLAN_SUCCESS, FETCH_PLAN_FAIL } from "constants/actionTypes/plan";

const fetchPlan = planId => {
  return { type: FETCH_PLAN, payload: { planId } };
};

export const fetchPlanSuccess = payload => {
  return { type: FETCH_PLAN_SUCCESS, isLoading: false, payload };
};

export const fetchPlanFail = payload => {
  return { type: FETCH_PLAN_FAIL, payload };
};

export default fetchPlan;
