import React from "react";
import { ColumnLayout } from "@amzn/awsui-components-react/";

import PolarisInputField from "views/components/Form/PolarisInputField";
import PolarisTextAreaField from "views/components/Form/PolarisTextAreaField";

const Goal = (partnershipGoal, index) => {
  console.log("Index");
  console.log(index);
  return (
    <div key={index}>
      <h2 style={{ marginBottom: 10 }}>Goal #{index + 1}</h2>
      <ColumnLayout>
        <div data-awsui-column-layout-root="true">
          <PolarisInputField
            label="Goal Name"
            name={`${partnershipGoal}.awsGoalGoalName`}
            required
            autofocus
            placeholder="Enter here..."
          />
          <PolarisTextAreaField
            label="Goal Description"
            name={`${partnershipGoal}.awsGoalGoalDescription`}
            required
            autofocus
            placeholder="Enter here..."
          />
        </div>
      </ColumnLayout>
      <ColumnLayout columns={2}>
        <div data-awsui-column-layout-root="true">
          <PolarisInputField
            label="Success"
            name={`${partnershipGoal}.awsGoalSuccess`}
            required
            autofocus
            placeholder="Enter here..."
          />
          <PolarisInputField
            label="Time"
            name={`${partnershipGoal}.awsGoalTime`}
            required
            autofocus
            placeholder="Enter here..."
          />
        </div>
      </ColumnLayout>
      <hr />
    </div>
  );
};

export default Goal;
