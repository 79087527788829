export const SELECT_OPTIONS = [
  {
    label: "No",
    id: "0"
  },
  {
    label: "Yes",
    id: "1"
  }
];
