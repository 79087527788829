export default function Foundation(report) {
  console.log("Started compiling Foundation section")
  let foundation =
    report && report.completed
      ? [
          {
            text: "Foundation",
            style: "sectionHeader"
          },
          [
            {text: `${report.partnerPrimaryFocus ? "Which AWS services are partner's primary focus today?" : ""}`, style: "sectionSubheader"},
            {text: `${report.partnerPrimaryFocus ? report.partnerPrimaryFocus : ""}`, style: "sectionBody"} 
          ],
          [
            {text: `${report.partnerAreaOfExpansion ? "Which service areas is the partner interested in expanding to?" : ""}`, style: "sectionSubheader"},
            {text: `${report.partnerAreaOfExpansion ? report.partnerAreaOfExpansion : ""}`, style: "sectionBody"} 
          ],
          [
            {text: `${report.newSolution ? "Does the partner have an idea for a new solution on an AWS product or service that they have not already taken to market?" : ""}`, style: "sectionSubheader"},
            {text: `${report.newSolution ? report.newSolution : ""}`, style: "sectionBody"}
          ]
        ]
      : null;

  console.log("Finished compiling Foundation section")
  return foundation;
}
