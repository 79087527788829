import React from 'react';
import { Button } from '@amzn/awsui-components-react/';
import Initative from './Initiative';

const Initiatives = ({ fields }) => {
  console.log(fields);
  return (
    <React.Fragment>
      {fields.map(Initative)}
      <Button
        onClick={() => {
          fields.push({});
        }}
      >
        Add Initiative
      </Button>
    </React.Fragment>
  );
};

export default Initiatives;
