import React from "react";
import {Button} from "@amzn/awsui-components-react/";
import {Link} from "react-router-dom";
import "./style.css";

const Card = ({ id, section }) => {
  return (
    <div className="awsui-util-container plan-card">
      <div className={`awsui-util-container-header ${section.completed ? `plan-card-header-complete` : section.started ? `plan-card-header-in-progress` : ``}`}>
        <h2>{section.title}</h2>
      </div>
      <div className={`plan-card-body ${section.completed ?  `plan-card-body-complete` : section.started ? `plan-card-body-in-progress` : ``}`}>
        <div>{section.description}</div>
      </div>
      <div className="awsui-util-container-footer plan-card-footer">
        <div className="plan-card-footer-div plan-card-footer-button">
          <Link to={`/plans/${id}/${section.module}`}>
            <Button>{section.completed ? "Edit" : "Open"}</Button>
          </Link>
        </div>
        <div className="plan-card-footer-div plan-card-footer-status">
          <h5 style={{padding: 0}} >STATUS</h5>
          <h5 style={{padding: 0}} >{section.completed ? "Complete" : section.started ? "In-Progress" : "Not Started"}</h5>
        </div>
      </div>
    </div>
  );
};

export default Card;
