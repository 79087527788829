import {
  FETCH_FORM,
  FETCH_FORM_SUCCESS,
  FETCH_FORM_FAIL,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_FAIL
} from 'constants/actionTypes/form';

const submitForm = payload => {
  return { type: SUBMIT_FORM, isLoading: true, payload };
};

//TODO: Finish
export const submitFormSuccess = payload => {
  return { type: SUBMIT_FORM_SUCCESS, isLoading: false, payload };
};

//TODO: Finish
export const submitFormFail = payload => {
  return { type: SUBMIT_FORM_FAIL, payload };
};

export const fetchForm = payload => {
  return { type: FETCH_FORM, payload };
};

//TODO: Finish
export const fetchFormSuccess = payload => {
  return { type: FETCH_FORM_SUCCESS, isLoading: false, payload };
};

//TODO: Finish
export const fetchFormFail = payload => {
  return { type: FETCH_FORM_FAIL, isLoading: false, payload };
};
export default submitForm;
