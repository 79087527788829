import React from "react";
import { connect } from "react-redux";
import { Flash } from "@amzn/awsui-components-react";

import { activeNotificationSelector } from "selectors/notifications";

import acknowledgeNotification from "actions/notifications";

class Notifications extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleAcknowledgeClick = this.handleAcknowledgeClick.bind(this);
  }

  handleAcknowledgeClick(e) {
    const { dispatch, acknowledgeNotification } = this.props;
    const id = e.target.getAttribute("data-id");
    dispatch(acknowledgeNotification(id));
  }

  render() {
    const { notifications } = this.props;
    return notifications.map(notification => (
      <Flash
        key={notification.id}
        type={notification.type}
        header={notification.header}
        dismissible={notification.dismissible}
        content={notification.content}
        onDismiss={e => this.handleAcknowledgeClick(e)}
        loading={notification.loading ? true : false}
        data-id={notification.id}
      />
    ));
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: activeNotificationSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    acknowledgeNotification: id => acknowledgeNotification(id),
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
