import React, {Component} from 'react';
import {AppLayout, Button, Spinner, Form, Input, FormField} from '@amzn/awsui-components-react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { reduxForm } from 'redux-form';
import axios from 'axios';

import fetchPlan from 'actions/plan';
import {getIdentityFromCognitoCookie, handleIfUnauthenticated} from 'constants/functions';

import Notifications from 'views/components/Dashboard/Notifications';
import NavigationDrawer from './NavigationDrawer';
import Sections from './Sections';
import appStore from 'store';
import generatePlan from 'actions/plan/generate';

import './style.css';
import {FeedbackHeader} from "views/components/FeedbackHeader";

const FORM_NAME = 'header';

class PlanDashboard extends Component {
  componentDidMount() {
    this.props.fetchPlan(this.props.match.params.planId);
  }

  constructor(props){
    super(props);
    this.state = {value: '', status: 'Autopopulate from Salesforce'};

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event){
    console.log("ech")
    this.setState({value: event.detail.value});
    console.log("Woohoo");
  }

  render() {
    const { history } = this.props;
    //const { handleSubmit, dispatch, history } = this.props;
    const { isLoading, plan } = this.props.plan;
    const { id, name, sectionsCompleted, year, sections } = plan;
    //TODO: Need to reconsider this......
    const cards = <Sections id={id} sections={sections} />;
    const header = (
      <div className="awsui-util-action-stripe-group">
        <Form>
          <div>
            <FormField
              className="awsui-util-d-b awsui-util-mb-m"
              stretch={true}
            >
              <Input name="salesforceId" placeholder="Enter partner's Salesforce url (https://apn-portal.my.salesforce.com/001XXXXXXXXXXXXXXX)..." value={this.state.value} onChange={this.handleChange}/>
            </FormField>
            <Button
              onClick={(values => {
                console.log(this.props.apiEndpoint)
                console.log(values)
                /* eslint no-restricted-globals:0 */   //Not sure if that's actually needed
                if(confirm("This will replace many of your fields with data imported from salesforce. This data is for the current year, and will be based on the plan ID you've entered in the box above. This is not recommended for plans not in the current year, as the data imported will be of the current year. This task will take up to a minute to complete, during which time you will still be able to edit your plan. This action is irreversible. Are you sure you want to do this?")){
                  //Grab the URL
                  let url = this.state.value
                  //Parse out the URL and make sure it's valid
                  var index = url.indexOf("001")
                  if(index === -1){
                    this.setState({...this.state, status:"Error, check url and try again"})
                  }else{
                    //Take only the Account ID from the url
                    url = url.substring(index,index+18)
                    //Let the user know a sync is in progress.
                    this.setState({...this.state, status: "Syncing..."})
                    //Make the request
                    axios.post(`${this.props.apiEndpoint}/salesforce/${this.props.plan.plan.id}`, {headers: {Authorization: getIdentityFromCognitoCookie()}, url: url, planId: this.props.plan.plan.id}) //We will need to set a longer axios timeout as more calls get added.
                    .then(function (response) {
                      // handle success
                      console.log(response);
                      //Change button
                      this.setState({...this.state, status:"Sync complete!"})// Should probably issue a notification long term
                    }.bind(this))
                    .catch(function (error) {
                      // handle error
                      console.log(error);
                      handleIfUnauthenticated(error);
                      this.setState({...this.state, status:"Error, check url and try again"});// Should probably issue a notification long term
                    }.bind(this))
                  }
                }
              })}
              text={this.state.status}
              loading={this.state.status === "Syncing..."} //Show loading while syncing
              disabled={this.state.status === "Sync complete!"} //Show disabled when complete
            />
            <Button
              onClick={e => {
                history.push('/');
              }}
              text="Close"
            />
            <Button
              onClick={e => {
                e.preventDefault();
                appStore().dispatch(generatePlan(id));
              }}
              variant="primary"
              text="Export Business Plan"
            />
          </div>
        </Form>
        {/* <Button variant="primary" text="Create Project Plan" /> */}
      </div>
    );

    const PlanningDashboard = (
      <div className="plan-section-parent">
          <div>
              {FeedbackHeader(history)}
          </div>
          <div className="awsui-util-container plan-card-header">
          <div className="awsui-util-action-stripe-large">
            <div className="awsui-util-action-stripe-title plan-card-header-text">
              <h1 className="plan-card-header-text-title">{`${name} - ${year}`}</h1>
              <h1 className="plan-card-header-text-completed">{`(${sectionsCompleted} of 9)`}</h1>
            </div>
            {header}
          </div>
        </div>
        <div className="plan-card-container">{cards}</div>
      </div>
    );

    const LoadingPlanDashboard = (
      <AppLayout notifications={<Notifications />} navigationHide={true} toolsHide={true} content={<Spinner />} />
    );

    const LoadedPlanDashboard = (
      <AppLayout
        notifications={<Notifications />}
        navigation={<NavigationDrawer id={id} />}
        toolsHide={true}
        content={PlanningDashboard}
      />
    );

    const DisplayedDashboard = isLoading ? LoadingPlanDashboard : LoadedPlanDashboard;
    return DisplayedDashboard;
  }
}

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(PlanDashboard);

const mapStateToProps = state => {
  return { isLoading: state.plan.isLoading, plan: state.plan, apiEndpoint: state.user.apiEndpoint };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    fetchPlan: planId => dispatch(fetchPlan(planId))
  };
};
const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent)

export default withRouter(connectDecoratedComponent);