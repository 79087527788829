import { GENERATE_PLAN, GENERATE_PLAN_SUCCESS, GENERATE_PLAN_FAIL } from "constants/actionTypes/plan/generate";

const generatePlan = planId => {
  return { type: GENERATE_PLAN, payload: { planId } };
};

export const generatePlanSuccess = payload => {
  return { type: GENERATE_PLAN_SUCCESS, isLoading: false, payload };
};

export const generatePlanFail = payload => {
  return { type: GENERATE_PLAN_FAIL, payload };
};

export default generatePlan;
