import { FETCH_PLANS, FETCH_PLANS_SUCCESS, FETCH_PLANS_FAIL } from "constants/actionTypes/plans";

const fetchPlans = () => ({ type: FETCH_PLANS, isLoading: true });

export const fetchPlansSuccess = plans => {
  return { type: FETCH_PLANS_SUCCESS, isLoading: false, payload: plans };
};

export const fetchPlansFail = () => {
  return { type: FETCH_PLANS_FAIL, isLoading: false };
};

export default fetchPlans;
