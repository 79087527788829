import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts.js";
import createPDFTemplate from "./createPDFTemplate";


export default function makePdf(forms, year, partnerName) {
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  var dd = createPDFTemplate(forms, year, partnerName);
  console.log("PDF Created!")
  let pdf = pdfMake.createPdf(dd)
  console.log("Everything finished compiling")
  pdf.download(`${partnerName} ${year} Business Plan.pdf`);
  console.log("PDF downloaded!")
  return;
}
