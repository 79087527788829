import React from "react";
import Card from "./Card";

const Sections = ({ id, sections }) => (
  <React.Fragment>
    {sections.map((section, index) => (
      <Card key={index} id={id} section={section} />
    ))}
  </React.Fragment>
);
export default Sections;
