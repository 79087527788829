import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, ColumnLayout, Form, FormSection} from '@amzn/awsui-components-react/';
import {reduxForm} from 'redux-form';

import PolarisTextAreaField from 'views/components/Form/PolarisTextAreaField';

import fetchPlan from 'actions/plan';
import dispatchSubmitForm, {redirectHome} from 'constants/functions';
import {FOUNDATION} from 'constants/forms';
import {FeedbackHeader} from "views/components/FeedbackHeader";

const FORM_NAME = FOUNDATION;

//TODO: Leaving empty because of all TextArea fields

class Foundation extends Component {
  componentDidMount() {
    this.props.fetchPlan(this.props.match.params.planId);
  }

  render() {
    const {handleSubmit, dispatch, planId, formName, history} = this.props;
    return (
      <div>
        <div>
          {FeedbackHeader(history)}
        </div>

        <Form
          onSubmit={handleSubmit}
          actions={
            <div>
              <Button text="Cancel" variant="link" onClick={() => redirectHome(history, planId)}/>
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: false,
                      planId,
                      formName
                    },
                    dispatch
                  );
                })}
                text="Save as Draft"
                variant="primary"
              />
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: true,
                      planId,
                      formName
                    },
                    dispatch
                  );
                  this.props.history.push('/plans/' + planId);
                })}
                text="Submit"
                variant="primary"
              />
            </div>
          }
        >
          <FormSection header="Foundation">
            <ColumnLayout>
              <div data-awsui-column-layout-root="true">
                <PolarisTextAreaField
                  label="Which AWS services are partner's primary focus today?"
                  name="partnerPrimaryFocus"
                  placeholder="Enter here..."
                />
                <PolarisTextAreaField
                  label="Which service areas is the partner interested in expanding to?"
                  name="partnerAreaOfExpansion"
                  placeholder="Enter here..."
                />
                <PolarisTextAreaField
                  label="Does the partner have an idea for a new solution on an AWS product or service that they have not already taken to market?"
                  placeholder="Enter here..."
                  name="newSolution"
                />
              </div>
            </ColumnLayout>
          </FormSection>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    fetchPlan: planId => dispatch(fetchPlan(planId)),
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Foundation);
const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
