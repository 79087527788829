import { FETCH_PLANS_SUCCESS } from "constants/actionTypes/plans";

const initialState = {
  sections: 0,
  isLoading: true,
  plans: [
    // {
    //   planId: "test",
    //   partnerName: "HashiCorp",
    //   year: 2018,
    //   sectionsCompleted: 4,
    //   completed: false
    // },
    // {
    //   planId: "test2",
    //   partnerName: "Splunk",
    //   year: 2016,
    //   sectionsCompleted: 5,
    //   completed: false
    // },
    // {
    //   planId: "test3",
    //   partnerName: "HashiCorp",
    //   year: 2020,
    //   sectionsCompleted: 1,
    //   completed: false
    // },
    // {
    //   planId: "test4",
    //   partnerName: "New Relic",
    //   year: 2016,
    //   sectionsCompleted: 8,
    //   completed: true
    // },
    // {
    //   planId: "test6",
    //   partnerName: "Infor",
    //   year: 2018,
    //   sectionsCompleted: 8,
    //   completed: true
    // }
  ]
};

const plans = (state = initialState, action) => {
  if (action.type === FETCH_PLANS_SUCCESS) {
    for (let i = 0; i < state.plans.length; i++) {
      for (let x = 0; x < action.payload.length; x++) {
        if (state.plans[i].planId === action.payload[x].planId) {
          action.payload.splice(x, 1);
        }
      }
    }
    return {
      ...state,
      isLoading: action.isLoading,
      plans: [...state.plans, ...action.payload]
    };
  }
  return state;
};

export default plans;
