import { timer } from 'rxjs';
import { map, repeat } from 'rxjs/operators';

import { acknowledgeNotifications } from 'actions/notifications';
import { DISMISSED } from 'constants/notifications';

const expireNotificationEpic = (action$, state$) =>
  timer(5000).pipe(
    map(action => {
      const notifications = state$.value.user.notifications;
      const acknowledgedNotifications = {};

      Object.keys(notifications)
        .filter(id => {
          return notifications[id].status !== DISMISSED;
        })
        .map(id => {
          const notification = notifications[id];
          const duration = new Date().getTime() - notification.date.getTime();
          const durationInSections = duration / 1000;
          const acknowledgedNotification = notification;
          if (durationInSections > 5) {
            acknowledgedNotification['status'] = DISMISSED;
            acknowledgedNotifications[id] = acknowledgedNotification;
          }
          return acknowledgedNotification;
        });
      return acknowledgeNotifications(acknowledgedNotifications);
    }),
    repeat()
  );

export default expireNotificationEpic;
