import {
  ACKNOWLEDGE_NOTIFICATION,
  ACKNOWLEDGE_NOTIFICATIONS,
  CREATE_SUCCESS_NOTIFICATION,
  CREATE_LOADING_NOTIFICATION,
  CREATE_ERROR_NOTIFICATION
} from 'constants/actionTypes/notifications';

import { FETCH_USERNAME_SUCCESS } from 'constants/actionTypes/user';
// import { ERROR, INFO, ACTIVE } from 'constants/notifications';

const initialState = {
  username: 'jeff',
  apiEndpoint: 'https://api.bpt-test.apn.a2z.com',//'http://dev-dsk-smthcol-2c-795b4e53.us-west-2.amazon.com:8080',//
  preferences: {},
  notifications: {
    // 1: {
    //   type: INFO,
    //   header: 'Notification',
    //   content: 'This is a notification',
    //   date: new Date(),
    //   dismissible: true,
    //   loading: false,
    //   status: ACTIVE
    // },
    // 2: {
    //   type: ERROR,
    //   header: 'Notification',
    //   content: 'This is a notification',
    //   date: new Date(),
    //   dismissible: true,
    //   loading: false,
    //   status: ACTIVE
    // }
    //     3: {
    //       type: SUCCESS,
    //       header: "Notification",
    //       content: "This is a notification",
    //       date: Date(),
    //       dismissible: true,
    //       loading: false,
    //       status: ACTIVE
    //     },
    //     4: {
    //       type: WARNING,
    //       header: "Notification",
    //       content: "This is a notification",
    //       date: Date(),
    //       dismissible: true,
    //       loading: false,
    //       status: ACTIVE
    //    }
  }
};

const user = (state = initialState, action) => {
  //TODO: Need to create this reducer
  if (action.type === CREATE_LOADING_NOTIFICATION) {
    const notificationId = action.payload.notificationId;
    delete action.payload.notificationId;
    return {
      ...state,
      notifications: {
        ...state.notifications,
        [notificationId]: {
          ...action.payload
        }
      }
    };
  } else if (action.type === CREATE_SUCCESS_NOTIFICATION) {
    const notificationId = action.payload.notificationId;
    delete action.payload.notificationId;
    return {
      ...state,
      notifications: {
        ...state.notifications,
        [notificationId]: {
          ...state.notifications[notificationId],
          ...action.payload
        }
      }
    };
  } else if (action.type === CREATE_ERROR_NOTIFICATION) {
    const notificationId = action.payload.notificationId;
    delete action.payload.notificationId;
    return {
      ...state,
      notifications: {
        ...state.notifications,
        [notificationId]: {
          ...state.notifications[notificationId],
          ...action.payload
        }
      }
    };
  } else if (action.type === ACKNOWLEDGE_NOTIFICATION) {
    const notificationId = action.payload.notificationId;
    delete action.payload.notificationId;
    return {
      ...state,
      notifications: {
        ...state.notifications,
        [notificationId]: {
          ...state.notifications[notificationId],
          ...action.payload
        }
      }
    };
  } else if (action.type === ACKNOWLEDGE_NOTIFICATIONS) {
    return {
      ...state,
      notifications: {
        ...state.notifications,
        ...action.payload
      }
    };
  } else if (action.type === FETCH_USERNAME_SUCCESS) {
    return {
      ...state,
      username: action.payload.username
    };
  }
  return state;
};

export default user;
