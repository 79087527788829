import uuidv4 from 'uuid/v4';

import submitForm from 'actions/form/';
import {createLoadingNotification} from 'actions/notifications';
import {
  AUTH_ENDPOINT,
  AUTH_SUBDOMAIN,
  CLIENT_ID,
  IDENTITY_PROVIDER,
  OAUTH_SCOPE,
  REDIRECT_URI,
  RESPONSE_TYPE
} from "config/CognitoAuthOptions";

const jwt = require('jsonwebtoken');

const dispatchSubmitForm = (values, dispatch) => {
  const isSubmitting = values.complete;
  const notificationId = uuidv4();
  values['notificationId'] = notificationId;
  dispatch(
    createLoadingNotification({
      notificationId,
      content: `${isSubmitting ? 'Submitting' : 'Saving'} form ${values.form}.`
    })
  );
  dispatch(submitForm(values));
};

export default dispatchSubmitForm;

export const redirectHome = (history, planId) => {
  history.push(`/plans/${planId}`);
};

export const getOptions = year => {
  const selectOptions = [];
  for (let i = -5; i <= 5; i++) {
    const calculatedYear = year + i;
    const selectOption = {
      label: calculatedYear.toString(),
      id: calculatedYear.toString()
    };
    selectOptions.push(selectOption);
  }
  return selectOptions;
};

/*Cognito related functions & Constants*/

const COGNITO_AUTH_URL =
  `https://${AUTH_SUBDOMAIN}.amazoncognito.com/${AUTH_ENDPOINT}?response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${OAUTH_SCOPE}&identity_provider=${IDENTITY_PROVIDER}`;

export const COGNITO_COOKIE_NAME = "bptID";

export const AUTH_INTERVAL_MS = 10 * 1000; //every 10 seconds, checking often due to inability to handle axios endpoint errors

//redirects the user's browser to go get a new cognito token.
export const getNewCognitoToken = () => {
  // alert(COGNITO_AUTH_URL);
  window.location.replace(COGNITO_AUTH_URL);
};

export const setCognitoCookie = (token, expireTime) => {
  document.cookie = COGNITO_COOKIE_NAME + "=" + token + ";expires=" + expireTime + ";path=/";
  //path=/ enables whole domain access
};

/**
 * Obtains the identity JWT token from a user's browser cookie storage. token is still in base64 format.
 * @returns {string|null} Returns null if cookie couldn't be found
 */
export const getIdentityFromCognitoCookie = () => {
  //stolen from w3Schools:  https://www.w3schools.com/js/js_cookies.asp
  let name = COGNITO_COOKIE_NAME + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const getAliasFromCognitoCookie = () => {
  let token = jwt.decode(getIdentityFromCognitoCookie(), {json: true});
  return !token ? null : (token.identities[0].userId); //identities[0] comes from a cognito-federated token structure.
};

/**
 * Method meant for error-handling after a 401 AJAX request
 * @param axiosError
 */
export const handleIfUnauthenticated = (axiosError) => {
  //todo this is hard due to rxjs & axios wrapping around requests, no standard way to see error code returned.
  console.log("AXIOS Error: " + JSON.stringify(axiosError));
};

/**
 * Checks the identity token in the browser to make sure it's  expired, if either condition is false
 * then we redirect the user to Cognito for a new token.
 */
export const checkTokenExpiration = () => {
  // console.log("Starting token check...");
  let idToken = getIdentityFromCognitoCookie();
  if (!idToken){ //cookie doesnt exist
    console.log("No authentication found, redirecting user...");
    getNewCognitoToken();
  }
  let decodedToken = jwt.decode(idToken, {json: true});

  if (decodedToken){
    //check expiration
    let currentTime = new Date().getTime()/1000;
    if (decodedToken.exp <= currentTime){
      console.log("Token Expired. Redirecting user...");
      getNewCognitoToken();
    }
  } else{ //decoded token had an error?
    console.log("No authentication found, redirecting user...");
    getNewCognitoToken();
  }
};