import React from 'react';
import { Field } from 'redux-form';
import { Select, FormField } from '@amzn/awsui-components-react';

const renderField = ({
  input: { onChange, onBlur, value },
  name,
  label,
  options,
  description,
  enableFiltering,
  meta: { touched, error, warning, pristine },
  autofocus = false,
  selectedId,
  stretch,
  hintText
}) => {
  /** proxy the onChange event into redux-form */
  const _onChange = event => onChange(event.detail.selectedId);

  /** proxy the onBlur event into redux-form */
  const _onBlur = event => onBlur(event.detail);

  return (
    <FormField
      className="awsui-util-d-b awsui-util-mb-m"
      description={description}
      stretch={stretch}
      errorText={touched && error}
      label={label}
      hintText={hintText}
    >
      <Select
        onChange={_onChange}
        onBlur={_onBlur}
        autofocus={autofocus}
        name={name}
        options={options}
        selectedId={value}
        enableFiltering={enableFiltering}
      />
    </FormField>
  );
};

const PolarisSelectField = ({
  label,
  name,
  description,
  options,
  selectedId,
  onChange,
  autofocus = false,
  stretch = true,
  enableFiltering = false,
  hintText
}) => {
  return (
    <Field
      label={label}
      name={name}
      description={description}
      component={renderField}
      autofocus={autofocus}
      stretch={stretch}
      options={options}
      selectedId={selectedId}
      onChange={onChange}
      enableFiltering
      type="select"
      hintText={hintText}
    />
  );
};

export default PolarisSelectField;
