import React from 'react';

const WizardNavigation = ({ steps, active, chooseStep }) => {
  const navigation = steps.map((step, i) => {
    return (
      <li key={i} className={'step-' + (i + 1)}>
        {
          <div onClick={() => chooseStep(i)} className={`${active === i ? 'active' : 'visited'}`}>
            {step}
          </div>
        }
      </li>
    );
  });
  return <ul className="awsui-list-unstyled custom-wizard-navigation">{navigation}</ul>;
};

export default WizardNavigation;
