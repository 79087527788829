export default function PartnerOpportunities(report, year) {
  console.log("Started compiling Partner Opportunities")
  let tableBodyOpportunities = [];

  (report["opportunitiesCount"+(year-0)] || report["customerWinsCount"+(year-0)] || report["opportunitiesCount"+(year-1)] || report["customerWinsCount"+(year-1)] || report["opportunitiesCount"+(year-2)] || report["customerWinsCount"+(year-2)]) && tableBodyOpportunities.push([{text: "Year:", color: '#FFFFFF', fillColor: '#4a80bd'}, {text: "Number of Qualified Opportunities Submitted:", color: '#FFFFFF', fillColor: '#4a80bd'}, {text: "Number of Customer Wins (Launch Opportunities):", color: '#FFFFFF', fillColor: '#4a80bd'}]);
  (report["opportunitiesCount"+(year-0)] || report["customerWinsCount"+(year-0)]) && tableBodyOpportunities.push([(year-0), (report["opportunitiesCount"+(year-0)] ? report["opportunitiesCount"+(year-0)] : ""), (report["customerWinsCount"+(year-0)] ? report["customerWinsCount"+(year-0)] : "")]);
  (report["opportunitiesCount"+(year-1)] || report["customerWinsCount"+(year-1)]) && tableBodyOpportunities.push([(year-1), (report["opportunitiesCount"+(year-1)] ? report["opportunitiesCount"+(year-1)] : ""), (report["customerWinsCount"+(year-1)] ? report["customerWinsCount"+(year-1)] : "")]);
  (report["opportunitiesCount"+(year-2)] || report["customerWinsCount"+(year-2)]) && tableBodyOpportunities.push([(year-2), (report["opportunitiesCount"+(year-2)] ? report["opportunitiesCount"+(year-2)] : ""), (report["customerWinsCount"+(year-2)] ? report["customerWinsCount"+(year-2)] : "")]);

  let partnerOpportunities =
    report && report.completed
      ? [
          {
            text: "Partner Opportunities",
            style: "sectionHeader"
          },
          {
            table: {
              body: tableBodyOpportunities
            },
            layout: {
              hLineWidth: function(i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function(i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
              }
              //For whatever reason, you cannot set text color and fillColor or the text becomes invisible
            }
          }
        ]
      : null;
  console.log("Finished compiling Partner Opportunities")
  return partnerOpportunities;
}
