import React from "react";
import {withRouter} from "react-router-dom";
import {Button} from '@amzn/awsui-components-react';
import {connect} from "react-redux";

import "./style.css"


export const FeedbackHeader = (props) => {
        return (
        <div className="container">
            <span className="left-component">
                {/*<BreadcrumbGroup label="Breadcrumbs" items={breadcrumbLink(props)}/>*/}
            </span>
            <span className="right-component">
                <span className="text-spacing">
                    Help us improve this tool.
                </span>
                <Button
                    text="Feedback?"
                    href="https://trawler.amazon.com/bpt"
                />
            </span>
        </div>
        )
};


const mapStateToProps = state => {
    return { username: state.user.username };
};
export default withRouter(connect(mapStateToProps)(FeedbackHeader));
