import React from "react";
import { Link } from "react-router-dom";

import appStore from "store";
import generatePlan from "actions/plan/generate";

const COLUMN_DEFINITIONS = [
  {
    id: "actions",
    cell: item => {
      return (
        <div>
          <Link to={`/plans/${item.planId}`}>Open</Link>
          <span style={{ marginLeft: 2, marginRight: 2 }}>|</span>
          <Link
            to={`/`}
            onClick={e => {
              e.preventDefault();
              appStore().dispatch(generatePlan(item.planId));
            }}
          >
            Export
          </Link>
          {/* <span style={{ marginLeft: 2, marginRight: 2 }}>|</span> */}
          {/* <Link to={`/plans/${item.planId}`}>Generate</Link> */}
        </div>
      );
    },
    header: () => "Actions",
    minWidth: "200px",
    allowLineWrap: true
  },
  {
    id: "partnerName",
    cell: item => item.partnerName,
    header: () => "Partner Name",
    minWidth: "160px",
    allowLineWrap: true
  },
  {
    id: "year",
    cell: item => item.year,
    header: () => "Year",
    minWidth: "80px",
    allowLineWrap: true
  },
  {
    id: "sectionsCompleted",
    cell: item => `${item.sectionsCompleted} of 9 `,
    header: () => "Sections Completed",
    minWidth: "160px",
    allowLineWrap: true
  },
  {
    id: "progress",
    cell: item => `${Math.round((item.sectionsCompleted / 9) * 100)}%`,
    header: () => "Progress",
    minWidth: "80px",
    width: "80px",
    allowLineWrap: true
  },
  {
    id: "status",
    cell: item => (item.completed ? "Complete" : item.sectionsCompleted === 0 ? "Not Started" : "In Progress"),
    header: () => "Status",
    minWidth: "80px",
    allowLineWrap: true
  }
];

const SORTABLE_COLUMNS = [
  {
    id: "partnerName",
    field: "partnerName",
    comparator: (item1, item2) => {
      return ("" + item1.partnerName).localeCompare(item2.partnerName);
    }
  },
  { id: "year", field: "year" },
  {
    id: "progress",
    field: "progress",
    comparator: (item1, item2) => {
      return item1.sectionsCompleted - item2.sectionsCompleted;
    }
  }
];

const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: "10 Partners" },
  { value: 30, label: "30 Partners" },
  { value: 50, label: "50 Partners" }
];

const CONTENT_SELECTOR_OPTIONS = [
  {
    label: "Partner Plan List Options",
    options: [
      { id: "actions", label: "Actions", editable: false, visible: true },
      { id: "partnerName", label: "Parter Name", editable: false, visible: true },
      { id: "year", label: "Year", editable: false, visible: true },
      { id: "sectionsCompleted", label: "Sections Completed", editable: true, visible: true },
      { id: "progress", label: "Progress", editable: true, visible: true },
      { id: "status", label: "Status", editable: true, visible: false }
    ]
  }
];

export default {
  COLUMN_DEFINITIONS,
  SORTABLE_COLUMNS,
  PAGE_SELECTOR_OPTIONS,
  CONTENT_SELECTOR_OPTIONS
};
