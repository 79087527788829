import React from 'react';

import WizardNavigation from './Navigation';

import './style.css';

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeStep: 0, farthestStep: 0 };
    this.activeStepComponent = null;
  }

  nextStep = () => {
    this.setState({ ...this.state, activeStep: this.state.activeStep + 1 });
  };

  previousStep = () => {
    this.setState({ ...this.state, activeStep: this.state.activeStep - 1 });
  };

  chooseStep = step => {
    this.setState({ ...this.state, activeStep: step });
  };

  render() {
    const { stepInfo } = this.props;
    const { activeStep } = this.state;
    const currentStepInfo = stepInfo[this.state.activeStep];
    const StepComponent = currentStepInfo.component;

    return (
      <div className="custom-layout awsui-util-pt-s">
        <div className="custom-col-nav">
          <WizardNavigation
            steps={stepInfo.map(step => step.title)}
            active={this.state.activeStep}
            chooseStep={step => this.chooseStep(step)}
          />
        </div>
        <div className="custom-col-content">
          <StepComponent
            activeStep={activeStep}
            nextStep={this.nextStep}
            numberOfSteps={stepInfo.length - 1}
            previousStep={this.previousStep}
          />
        </div>
      </div>
    );
  }
}

export default Wizard;
