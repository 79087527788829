import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { ColumnLayout, Form, FormSection } from '@amzn/awsui-components-react/';

import WizardFooter from 'views/components/Wizard/Footer';
import PolarisInputField from 'views/components/Form/PolarisInputField';
import PolarisTextAreaField from 'views/components/Form/PolarisTextAreaField';

import { PARTNER_PLANNING } from 'constants/forms';
import { validateForm } from 'views/components/PartnerPlanModules/PartnerPlanning/validation';
import {percentageValidation} from "constants/forms/validation";

const FORM_NAME = PARTNER_PLANNING;

class RevenueBreakdown extends Component {
  render() {
    const { activeStep, handleSubmit, nextStep, numberOfSteps, previousStep } = this.props;

    return (
      <Form
        onSubmit={handleSubmit}
        actions={
          <WizardFooter
            activeStep={activeStep}
            nextStep={nextStep}
            numberOfSteps={numberOfSteps}
            previousStep={previousStep}
            handleSubmit={handleSubmit}
            formName={FORM_NAME}
          />
        }
      >
        <FormSection
          header="Revenue Breakdown"
          description="What percentage of your sales comes from each company size segment today? (by rought order of 10% points)."
        >
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <PolarisInputField label="Enterprise" name="enterprise" placeholder="%" validate={percentageValidation} />
              <PolarisInputField label="Upper mid-market" name="upperMidMarket" placeholder="%" validate={percentageValidation} />
              <PolarisInputField label="Lower mid-market" name="lowerMidMarket" placeholder="%" validate={percentageValidation} />
              <PolarisInputField label="SMB" name="smb" placeholder="%" validate={percentageValidation} />
            </div>
          </ColumnLayout>
          <div>
            <p className="awsui-text-secondary">How does your partner align in market?</p>
          </div>
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <PolarisInputField label="Organization" name="alignMarketOrganization" placeholder="Enter here..." />
              <PolarisInputField label="Maps to AWS Territory" name="alignMarketMapToAWS" placeholder="Enter here..." />
              <PolarisInputField label="Sellers/Accredited" name="sellersAccredited" placeholder="Enter here..." />
              <PolarisInputField
                label="Technical Sellers/Certified"
                name="technicalSellersCertified"
                placeholder="Enter here..."
              />
              <PolarisInputField
                label="Consultants/Certified"
                name="consultantsCertified"
                placeholder="Enter here..."
              />
              <PolarisInputField label="In Direct Channels" name="inDirectChannels" placeholder="Enter here..." />
              <PolarisInputField
                label="AWS Reference Accounts"
                name="awsReferenceAccounts"
                placeholder="Enter here..."
              />
            </div>
          </ColumnLayout>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <PolarisTextAreaField
                label="How does your partner make money?"
                name="partnerMakeMoney"
                placeholder="Enter here..."
              />
              <PolarisTextAreaField
                label="What impact does resell (margin) have on your partner's business?"
                name="resellMarginImpact"
                placeholder="Enter here..."
              />
              <PolarisTextAreaField
                label="Are there other public cloud businesses?"
                name="otherPublicCloudBusinesses"
                placeholder="Enter here..."
              />
              <PolarisTextAreaField
                label="What % of their overall business comes from Public Cloud(s)?"
                name="percentFromPublicCloud"
                placeholder="Enter here..."
                validate={percentageValidation}
              />
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validateForm
})(RevenueBreakdown);

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
