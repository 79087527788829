import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, FieldArray } from 'redux-form';
import { Form, FormSection } from '@amzn/awsui-components-react/';

import WizardFooter from 'views/components/Wizard/Footer';
import Initiatives from './Initiatives';

import { PARTNER_PLANNING } from 'constants/forms';
import { validateForm } from 'views/components/PartnerPlanModules/PartnerPlanning/validation';
const FORM_NAME = PARTNER_PLANNING;

class TopInitiatives extends Component {
  render() {
    const { activeStep, handleSubmit, nextStep, numberOfSteps, previousStep } = this.props;

    return (
      <Form
        onSubmit={handleSubmit}
        actions={
          <WizardFooter
            activeStep={activeStep}
            nextStep={nextStep}
            numberOfSteps={numberOfSteps}
            previousStep={previousStep}
            handleSubmit={handleSubmit}
            formName={FORM_NAME}
          />
        }
      >
        <FormSection
          header="Top Initiatives"
          description="Describe this initiative, how it impacts the business, how it will be developed, what the goal is, and metrics of success."
        >
          <FieldArray name="partnerGoals" component={Initiatives} />
        </FormSection>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validateForm
})(TopInitiatives);

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
