import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./style.css";

const NavBar = ({ username }) => (
  <div className="aws-page-header" id="h">
    <header className="aws-page-main-header" role="banner">
      <div className="page-header-content">
        <div className="page-header-left">
          <Link to="/">
            <img src="/images/aws-logo-white.svg" alt="Amazon Web Services" />
          </Link>
          <div className="aws-header-bpt-div" to="/">
            <h1 className="aws-header-bpt"><a href="/" style={{color: "white", "text-decoration": "none"}}>Business Planning Tool</a></h1>
          </div>
        </div>
        <div className="page-header-right">{username}</div>
      </div>
    </header>
  </div>
);

const mapStateToProps = state => {
  return { username: state.user.username };
};

export default withRouter(connect(mapStateToProps)(NavBar));
