import axios from 'axios';
import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

import {IMPORT_PLAN} from 'constants/actionTypes/plan/import';

import fetchPlans from 'actions/plans';
import {importPlanFail, importPlanSuccess} from 'actions/plan/import';
import {createErrorNotification, createSuccessNotification} from 'actions/notifications';
import {getIdentityFromCognitoCookie, handleIfUnauthenticated} from "constants/functions";

const importPlanEpic = (action$, state$) => { //Please note that any errors thrown will be visible to the customer. This makes it easy to relay errors, but make sure they're clean. 
  return action$.pipe(
    ofType(IMPORT_PLAN),
    mergeMap(response => {
      if (state$.value.plans.plans.filter(plan => plan.partnerName === response.payload.reportName && plan.year === response.payload.year).length) {
        console.error("Report already exists!")
        throw new Error('Report Already Exists');
      } 
      else {
        let data = {
          reportName: response.payload.reportName,
          year: response.payload.year,
          notificationId: response.payload.notificationId,
          userId: state$.value.user.username,
          section: response.payload.importFile
        };
        console.log("Making new Report")
        return from(axios.post(`${state$.value.user.apiEndpoint}/plan`, data , {headers: {Authorization: getIdentityFromCognitoCookie()}}));
      }
    }),
    mergeMap(response => {
      if(response.data.body.error){
        throw new Error(response.data.body.error)
      }
      return [
        importPlanSuccess(response.data.body.message),
        createSuccessNotification({
          notificationId: response.data.body.message.notificationId,
          content: `Creating a new action plan complete.`
        }),
        fetchPlans()
      ];
    }),
    catchError(err => {
      console.log("Received Server Error");
      console.error(err);
      handleIfUnauthenticated(err);
      return of(
        importPlanFail(),
        createErrorNotification({
          notificationId: '',
          content: `Creating a new action plan failed. ${err ? err : null}`
        })
      );
    })
  );
};

export default importPlanEpic;
