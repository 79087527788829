export default function PartnerGoals(report) {

  console.log("REPORT: ")
  console.log(report)

  let partnerGoals = report && report.completed ? 
    [
      {
        text: "Partner Goals",
        style: "sectionHeader"
      },
      {
        text: `${report.partnerGoals ? "Partner's overall business goals and strategy for next year" : ""}`, style: "sectionSortaSubheader"
      }
    ] : null;
  let goalNum = 1;
  console.log("Ech")
  if (report.partnerGoals) {
    for (let goal of report.partnerGoals){
      console.log(goal.partnerGoalGoalName)
      partnerGoals.push([
        [
          {text: "Goal " + goalNum, style: "sectionSubheader"}
        ],
        [
          {text: `${goal.partnerGoalGoalName ? "Goal Name: " : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.partnerGoalGoalName ? goal.partnerGoalGoalName : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.partnerGoalGoalDescription ? "Goal Description:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.partnerGoalGoalDescription ? goal.partnerGoalGoalDescription : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.partnerGoalSuccess ? "Success:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.partnerGoalSuccess ? goal.partnerGoalSuccess : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.partnerGoalTime ? "Time:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.partnerGoalTime ? goal.partnerGoalTime : ""}`, style: "sectionBody"}
        ],
      ]);
      goalNum++;
    }
  }
  if(report.awsPartnershipGoals){
    goalNum = 1;
    partnerGoals.push({text: "AWS goals for the partner?", style: "sectionSortaSubheader"});
    console.log("ech2")
    for (let goal of report.awsPartnershipGoals){
      console.log(goal.awsGoalGoalName)
      partnerGoals.push([
        [
          {text: "Goal " + goalNum, style: "sectionSubheader"}
        ],
        [
          {text: `${goal.awsGoalGoalName ? "Goal Name: " : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.awsGoalGoalName ? goal.awsGoalGoalName : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.awsGoalGoalDescription ? "Goal Description:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.awsGoalGoalDescription ? goal.awsGoalGoalDescription : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.awsGoalSuccess ? "Success:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.awsGoalSuccess ? goal.awsGoalSuccess : ""}`, style: "sectionBody"}
        ],
        [
          {text: `${goal.awsGoalTime ? "Time:" : ""}`, style: "sectionSubsubheader"},
          {text: `${goal.awsGoalTime ? goal.awsGoalTime : ""}`, style: "sectionBody"}
        ],
      ]);
      goalNum++;
    }
  }
  return partnerGoals;
}
