export default function BusinessExpansion(report) {
  console.log("Started compiling Business Expansion section")
  let businessExpansion =
    report && report.completed
      ? [
          {
            text: "Business Expansion",
            style: "sectionHeader"
          },
          [
            {text: `${report.expansionPlan ? "What are partner's plan to expand?" : ""}`, style: "sectionSubheader"}, 
            {text: `${report.expansionPlan ? report.expansionPlan : ""}`, style: "sectionBody"}
          ],
          [
            {text: `${report.partnerIdeation ? "Does partner do ideation with AWS today?" : ""}`, style: "sectionSubheader"}, 
            {text: `${report.partnerIdeation ? report.partnerIdeation : ""}`, style: "sectionBody"}
          ],
          [
            {text: `${report.awsRecommendations ? "Is partner interested in AWS recommendations of new expansion areas which may be relevant to them?" : ""}`, style: "sectionSubheader"},
            {text: `${report.awsRecommendations ? report.awsRecommendations : ""}`, style: "sectionBody"}
          ]
        ]
      : null;

  console.log("Finished compiling Business Expansion section")
  return businessExpansion;
}
