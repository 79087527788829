import React from "react";
import { Route } from "react-router-dom";
import { AppLayout } from "@amzn/awsui-components-react";
import NavigationDrawer from "views/components/Dashboard/Plan/NavigationDrawer";
import Notifications from "views/components/Dashboard/Notifications";

const SectionRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <AppLayout
          navigation={<NavigationDrawer id={props.match.params.planId} />}
          toolsHide={true}
          content={Component}
          notifications={<Notifications />}
        />
      )}
    />
  );
};

export default SectionRoute;
