import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, ColumnLayout, Form, FormSection} from '@amzn/awsui-components-react/';
import {reduxForm} from 'redux-form';

import PolarisInputField from 'views/components/Form/PolarisInputField';

import fetchPlan from 'actions/plan';
import dispatchSubmitForm, {redirectHome} from 'constants/functions';
import {PARTNER_METRICS} from 'constants/forms';
import {
  decimalNumberValidation,
  nameListValidation,
  requiredValidation,
  websiteValidation,
  wholeNumberValidation
} from "constants/forms/validation";
import {FeedbackHeader} from "views/components/FeedbackHeader";

const FORM_NAME = PARTNER_METRICS;

class PartnerMetrics extends Component {
  componentDidMount() {
    this.props.fetchPlan(this.props.match.params.planId);
  }

  render() {
    const {handleSubmit, dispatch, planId, formName, history, year} = this.props;
    return (
      <div>
        <div>
          {FeedbackHeader(history)}
        </div>
        <Form
          onSubmit={handleSubmit}
          actions={
            <div>
              <Button text="Cancel" variant="link" onClick={() => redirectHome(history, planId)}/>
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: false,
                      planId,
                      formName
                    },
                    dispatch
                  );
                })}
                text="Save as Draft"
                variant="primary"
              />
              <Button
                onClick={handleSubmit(values => {
                  dispatchSubmitForm(
                    {
                      values,
                      form: FORM_NAME,
                      completed: true,
                      planId,
                      formName
                    },
                    dispatch
                  );
                  redirectHome(history, planId)
                })}
                text="Submit"
                variant="primary"
              />
            </div>
          }
        >
          <FormSection header="APN Partner">
            <ColumnLayout>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label="Company Website"
                  name="companyWebsite"
                  hintText="Required"
                  autofocus
                  placeholder="Enter company website here (ex. https://www.amazon.com)."
                  validate={[requiredValidation, websiteValidation]}
                />
                <PolarisInputField
                  label="Company Revenue"
                  name="companyRevenue"
                  required
                  autofocus
                  placeholder="Enter partner's revenue..."
                  // validate={decimalNumberValidation} removed validation as it's possible for the partner to not disclose revenue (I.e. N/A)
                />
                <PolarisInputField
                  label="Office Locations"
                  name="officeLocation"
                  required
                  autofocus
                  placeholder="Enter partner's office location..."
                  // validate={alphanumericValidation} TODO: very hard, due to international addresses.
                />
                <PolarisInputField
                  label="Number of Employees by Geo/Office"
                  name="employeeCount"
                  required
                  autofocus
                  placeholder="Enter number of employees by Geo/Office..."
                  // validate={wholeNumberValidation} Removed validation as it's multiple offices which may have special characters
                />
                <PolarisInputField
                  label="Number of Sales Stakeholders"
                  name="salesStakeholderCount"
                  required
                  autofocus
                  placeholder="Enter number of sales stakeholders..."
                  validate={wholeNumberValidation}
                />
                <PolarisInputField
                  label="Number of Technical Stakeholders"
                  name="technicalStakeholdersCount"
                  required
                  autofocus
                  placeholder="Enter number of technical stakeholders..."
                  validate={wholeNumberValidation}
                />
                <PolarisInputField
                  label="Number of Marketing Stakeholders"
                  name="marketingStakeholdersCount"
                  required
                  autofocus
                  placeholder="Enter number of marketing stakeholders..."
                  validate={wholeNumberValidation}
                />
                <PolarisInputField
                  label="APN Partner Tier"
                  name="apnPartnerTier"
                  hintText="Required"
                  required
                  autofocus
                  validate={requiredValidation}
                />
                <PolarisInputField
                  label="Key Partners"
                  name="keyPartners"
                  hintText="Required"
                  required
                  autofocus
                  validate={requiredValidation}
                />
                <PolarisInputField
                  label="APN Executive Sponsors"
                  name="apnExecutiveSponsors"
                  required
                  autofocus
                  placeholder="Enter here..."
                  validate={nameListValidation}
                />
                {/** This should be a select */}
                <PolarisInputField
                  label="Current APN Programs"
                  name="currentApnPrograms"
                  hintText="Required"
                  required
                  autofocus
                  validate={requiredValidation}
                />
                <PolarisInputField
                  label="Competitive presence(MS, GCP...)"
                  name="competitivePresense"
                  required
                  autofocus
                  placeholder="Enter here..."
                />
              </div>
            </ColumnLayout>
          </FormSection>
          <FormSection header="Partner Metrics">
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <PolarisInputField
                  label={`${year} Direct Partner Revenue`}
                  name={`${year}PartnerRevenue`}
                  required
                  autofocus
                  placeholder="$"
                  validate={decimalNumberValidation}
                />
                <PolarisInputField
                  label={`${year} Direct Partner Revenue YoY % Change`}
                  name={`${year}revenueChangeInfluenced`}
                  required
                  autofocus
                  placeholder="%"
                  validate={decimalNumberValidation}
                />
                <PolarisInputField
                  label={`${year - 1} Direct Partner Revenue`}
                  name={`${year - 1}PartnerRevenue`}
                  required
                  autofocus
                  placeholder="$"
                  validate={decimalNumberValidation}
                />
                <PolarisInputField
                  label={`${year - 1} Direct Partner Revenue YoY % Change`}
                  name={`${year - 1}revenueChangeInfluenced`}
                  required
                  autofocus
                  placeholder="%"
                  validate={decimalNumberValidation}
                />
                <PolarisInputField
                  label={`${year - 2} Direct Partner Revenue`}
                  name={`${year - 2}PartnerRevenue`}
                  required
                  autofocus
                  placeholder="$"
                  validate={decimalNumberValidation}
                />
                <PolarisInputField
                  label={`${year - 2} Direct Partner Revenue YoY % Change`}
                  name={`${year - 2}revenueChangeInfluenced`}
                  required
                  autofocus
                  placeholder="%"
                  validate={decimalNumberValidation}
                />
              </div>
            </ColumnLayout>
          </FormSection>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id,
    year: state.plan.plan.year,
    formName: FORM_NAME,
    initialValues: {
      ...state.plan.plan.forms[FORM_NAME]
    }
  };
};
const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    fetchPlan: planId => dispatch(fetchPlan(planId)),
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  // validate: validateForm
})(PartnerMetrics);
const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
