import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from '@amzn/awsui-components-react/';

import dispatchSubmitForm, { redirectHome } from 'constants/functions';

const WizardFooter = ({
  activeStep,
  dispatch,
  formName,
  handleSubmit,
  history,
  nextStep,
  numberOfSteps,
  planId,
  previousStep
}) => {
  const backButton =
    activeStep !== 0 ? <Button className="prev-step-btn" text="Previous" onClick={previousStep} /> : null;

  const nextButton =
    activeStep !== numberOfSteps ? (
      <Button className="next-step-btn" text="Next" variant="primary" onClick={nextStep} />
    ) : (
      <Button
        onClick={handleSubmit(values => {
          console.log(values);
          dispatchSubmitForm(
            {
              values,
              form: formName,
              completed: true,
              planId,
              formName
            },
            dispatch
          );
          redirectHome(history, planId);
        })}
        text="Submit"
        variant="primary"
      />
    );

  return (
    <div>
      <Button text="Cancel" variant="link" onClick={() => redirectHome(history, planId)} />
      {backButton}
      <Button
        onClick={handleSubmit(values => {
          console.log(values);
          dispatchSubmitForm(
            {
              values,
              form: formName,
              completed: false,
              planId,
              formName
            },
            dispatch
          );
        })}
        text="Save as Draft"
      />
      {nextButton}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    planId: state.plan.plan.id
  };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(WizardFooter);

export default withRouter(connectDecoratedComponent);
