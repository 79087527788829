import { combineEpics } from 'redux-observable';
import createPlanEpic from 'epic/plan/create';
import expireNotificationEpic from 'epic/notifications/expire';
import fetchPlanEpic from 'epic/plan';
import fetchPlansEpic from 'epic/plans';
import fetchUserEpic from 'epic/user';
import generatePlanEpic from 'epic/plan/generate';
import importPlanEpic from 'epic/plan/import';
import submitFormEpic from 'epic/form';

const rootEpic = combineEpics(
  createPlanEpic,
  expireNotificationEpic,
  fetchPlansEpic,
  fetchPlanEpic,
  fetchUserEpic,
  importPlanEpic,
  generatePlanEpic,
  submitFormEpic
);

export default rootEpic;
