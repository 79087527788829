export default function Marketing(report) {
  console.log("Started compiling Marketing section")
  let marketing =
    report && report.completed
      ? [
          {
            text: "Marketing",
            style: "sectionHeader"
          },
          [
            {text: `${report.hasMarketingPoc ? "Do we have a marketing POC?" : ""}`, style: "sectionSubheader"},
             // eslint-disable-next-line 
            {text: `${report.hasMarketingPoc ? (report.hasMarketingPoc == 0 ? "No" : "Yes") : ""}`, style: "sectionBody"} //If we don't do this, it comes out as 1 or 0. No we can't use '===', ignore that warning. I tried it and it stops working.
          ],
          [
            {text: `${report.hasMarketingPlan ? "Do we have a partner-led marketing plan for AWS in place?" : ""}`, style: "sectionSubheader"},
             // eslint-disable-next-line 
            {text: `${report.hasMarketingPlan ? (report.hasMarketingPlan == 0 ? "No" : "Yes") : ""}`, style: "sectionBody"} //If we don't do this, it comes out as 1 or 0. No we can't use '===', ignore that warning. I tried it and it stops working.
          ],
          [
            {text: `${report.effectiveness ? "Rate partner's marketing effectiveness based on previous experience:" : ""}`, style: "sectionSubheader"},
            {text: `${report.effectiveness ? report.effectiveness : ""}`, style: "sectionBody"}
          ],
          [
            {text: `${report.marketingBudget ? "What is your marketing budget for public cloud?" : ""}`, style: "sectionSubheader"},
            {text: `${report.marketingBudget ? report.marketingBudget : ""}`, style: "sectionBody"}
          ],
          [
            {text: `${report.plannedSpend ? "What is your planned spend with AWS Partner Network (APN)?" : ""}`, style: "sectionSubheader"},
            {text: `${report.plannedSpend ? report.plannedSpend : ""}`, style: "sectionBody"}
          ],
          [
            {text: `${report.marketPlaceBudget ? "What is your AWS Marketplace budget?" : ""}`, style: "sectionSubheader"},
            {text: `${report.marketPlaceBudget ? report.marketPlaceBudget : ""}`, style: "sectionBody"}
          ],
          [
            {text: `${report.primaryContact ? "Who is the primary marketing contact? Are they dedicated to AWS?" : ""}`, style: "sectionSubheader"},
            {text: `${report.primaryContact ? report.primaryContact : ""}`, style: "sectionBody"}
          ],
          [
            {text: `${report.bdrResources ? "Do you have ISR/LDR/BDR resources available for lead follow-up and qualification for sales, and are they trained on this cloud solution on AWS?" : ""}`, style: "sectionSubheader"},
            {text: `${report.bdrResources ? report.bdrResources : ""}`, style: "sectionBody"}
          ]
        ]
      : null;
  console.log("Finished compiling Marketing section")

  return marketing;
}
