import React from 'react';
import { Button } from '@amzn/awsui-components-react';
import './style.css';

const Header = ({ counter, openCreatePage, openImportPage }) => (
  <div className="awsui-util-action-stripe">
    <div className="awsui-util-action-stripe-title">
      <h1 className="awsui-util-font-size-3">
        <strong className="header-text-title">Business Plans</strong>
        {counter ? <span className="header-text-plans">({counter})</span> : null}
      </h1>
    </div>
    <div className="awsui-util-action-stripe-group">
      <Button onClick={openImportPage} variant="primary" text="Import Business Plan" />
      <Button onClick={openCreatePage} variant="primary" text="New Business Plan" />
    </div>
  </div>
);

export default Header;
