import React, {Component} from "react";
import {AppLayout} from "@amzn/awsui-components-react";

import Notifications from "../Notifications";
import PartnerTable from "./Table/";

import "./style.css";
import FeedbackHeader from "views/components/FeedbackHeader";

class PlansDashboard extends Component {
  state = {
    loading: true,
    data: [],
    newReport: {}
  };

  render() {
    const appLayoutLabels = {
      navigationToggle: "Open navigation drawer",
      navigationClose: "Close navigation drawer",
      toolsToggle: "Open tools drawer",
      toolsClose: "Close navigation drawer"
    };

    return (
      <div>
        <AppLayout
          content={[<FeedbackHeader/>, <PartnerTable submitModal={this.submitModal}/>]}
          notifications={<Notifications />}
          navigationHide={true}
          toolsHide={true}
          labels={appLayoutLabels}
        />
      </div>
    );
  }
}

export default PlansDashboard;
