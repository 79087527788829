import PartnerMetrics from "views/components/PartnerPlanModules/PartnerMetrics";
import PartnerOpportunities from "views/components/PartnerPlanModules/PartnerOpportunities";
import PartnerPlanning from "views/components/PartnerPlanModules/PartnerPlanning";
import PartnerGoals from "views/components/PartnerPlanModules/PartnerGoals";
import Marketing from "views/components/PartnerPlanModules/Marketing";
import CoSelling from "views/components/PartnerPlanModules/CoSelling";
import BusinessExpansion from "views/components/PartnerPlanModules/BusinessExpansion";
import Foundation from "views/components/PartnerPlanModules/Foundation";
import SalesAndTechnicalEnablement from "views/components/PartnerPlanModules/SalesAndTechnicalEnablement";

export default [
  {
    title: "Partner Metrics",
    module: "PartnerMetrics",
    description: "Provide details about the partner: stakeholders, programs, revenue.",
    completed: false,
    started: false,
    component: PartnerMetrics
  },
  {
    title: "Partner Opportunities",
    module: "PartnerOpportunities",
    description: "Provide details on submitted and launched opportunities.",
    completed: false,
    started: false,
    component: PartnerOpportunities
  },
  {
    title: "Business Expansion",
    module: "BusinessExpansion",
    description: "Provide details around business expansion and big bet areas.",
    completed: false,
    started: false,
    component: BusinessExpansion
  },
  {
    title: "Marketing",
    module: "Marketing",
    description: "Provide summary of Marketing Goals.",
    completed: false,
    started: false,
    component: Marketing
  },
  {
    title: "Sales and Tech Enablement",
    module: "SalesAndTechnicalEnablement",
    description: "Provide sales and technical enablement details for the partner.",
    completed: false,
    started: false,
    component: SalesAndTechnicalEnablement
  },
  {
    title: "Partner Planning",
    module: "PartnerPlanning",
    description: "Provide an executive summary, APN resource utilization, and partner business model detail.",
    completed: false,
    started: false,
    component: PartnerPlanning
  },
  {
    title: "Co-Selling",
    module: "CoSelling",
    description: "Provide Partner sales and co-selling details.",
    completed: false,
    started: false,
    component: CoSelling
  },
  {
    title: "Partner Goals",
    module: "PartnerGoals",
    description: "Provide partner goals, and AWS partnership details.",
    completed: false,
    started: false,
    component: PartnerGoals
  },
  {
    title: "Foundation",
    module: "Foundation",
    description: "Provide the foundation of partner's business.",
    completed: false,
    started: false,
    component: Foundation
  }
];
