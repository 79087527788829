import { createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import rootEpic from "epic/index";
import rootReducer from "reducers/index";
import fetchPlans from "actions/plans";

const epicMiddleware = createEpicMiddleware();

const appStore = () => {
  const store = createStore(rootReducer, applyMiddleware(epicMiddleware));

  epicMiddleware.run(rootEpic);
  store.dispatch(fetchPlans());
  return store;
};

export default appStore;
