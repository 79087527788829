import React from "react";
import { Button, ColumnLayout } from "@amzn/awsui-components-react/";
import Goal from "./Goal";

const Goals = ({ fields }) => {
  console.log(fields);
  return (
    <ColumnLayout borders="horizontal">
      <div data-awsui-column-layout-root="true">
        {fields.map(Goal)}
        <Button
          onClick={() => {
            fields.push({});
          }}
        >
          Add Partner Goal
        </Button>
      </div>
    </ColumnLayout>
  );
};

export default Goals;
