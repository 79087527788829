import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {AppLayout, Button, ColumnLayout, Form, FormSection} from '@amzn/awsui-components-react/';
import {reduxForm} from 'redux-form';
//import appStore from 'store';
import uuidv4 from 'uuid/v4';
import * as XLSX from 'xlsx'

import PolarisInputField from 'views/components/Form/PolarisInputField';
import PolarisSelectField from 'views/components/Form/PolarisSelectField';

import Notifications from '../Notifications';

import {createLoadingNotification} from 'actions/notifications';
import importPlan from 'actions/plan/import';
import {getIdentityFromCognitoCookie, getOptions, handleIfUnauthenticated} from 'constants/functions';
import {validateForm} from 'views/components/Dashboard/Import/validation';

const FORM_NAME = 'ImportPartnerPlanModal';

//TODO: Finish converting the fields

class ImportPartnerPlan extends Component {
  state = {
    disableUpload: false,
    uploadLoading: false,
    link: "http://amazon.com",
    importFile: {}
  };


  getLink = async() => {
    try {
      const response = await axios.get(this.props.apiEndpoint + '/template', {headers: {Authorization: getIdentityFromCognitoCookie()}});
      console.log(response);
      return response.data.message;
    } catch(error) {
      handleIfUnauthenticated(error);
      console.log("Error retrieving download link for template")
      console.log(error);
    }
  }

  async componentDidMount(){
    const response = await this.getLink();
    this.setState({
      ...this.state,
      link: response
    });
    console.log("Template link is live now")
  }

  importXlsx = event => {

    this.setState({ ...this.state, disableUpload: true, uploadLoading: true });
    const [file] = event.target.files;
    console.log(file)

    /* Create FileReader to read XLSX doc */
    const reader = new FileReader();
    /* Set reader event */
    reader.onload = (evt) => { 
      /* Parse binary XLSX to workbook format */
      const binaryString = evt.target.result;
      const workbook = XLSX.read(binaryString, {type: 'binary'});
      /* Grab correct (and only(?)) worksheet from workbook */
      const worksheetName = "Inputs";
      const worksheet = workbook.Sheets[worksheetName];
      /* Convert worksheet to json */
      const worksheetJson = XLSX.utils.sheet_to_json(worksheet, {header:1});
      /* Log worksheet for dev purposes */
      this.formatJSON(worksheetJson)
    };
    reader.readAsBinaryString(file);
  };

  formatJSON = json => {
    
    /* Deal with remapping the JSON (JSON format stolen from test.js in views\components\pdf\) */
    let report = {
      "BusinessExpansion": {
        "awsRecommendations": json[275][1],
        "completed": false,
        "expansionPlan": json[273][1],
        "partnerIdeation": json[274][1],
        "started": function(){
          if(json[275][1] || json[273][1] || json[274][1]){
            return true;
          } else {
            return false;
          }
        }()
      },
      "CoSelling": {
        "competitiveRelationship": json[264][1],
        "completed": false,
        "engagedCoSellingEffots": json[266][1],
        "newSolutionDevelopment": json[267][1],
        "publicCloudRelationship": json[265][1],
        "sellerIncentive": json[263][1],
        "solutionDriveAWSSalesGoals": json[268][1],
        "started": function(){
          if(json[264][1] || json[266][1] || json[267][1] || json[265][1] || json[263][1] || json[268][1] || json[270][1]){
            return true;
          } else {
            return false;
          }
        }(),
        "successfulUseCases": json[270][1]
      },
      "Foundation": {
        "completed": false,
        "partnerAreaOfExpansion": json[129][1],
        "partnerPrimaryFocus": json[128][1],
        "started": function(){
          if(json[129][1] || json[128][1] || json[130][1]){
            return true;
          } else {
            return false;
          }
        }(),
        "undefined": json[130][1] //TODO: I'm almost 100% certain this should be named "newSolution"
      },
      "Marketing": {
        "bdrResources": json[259][1],
        "completed": false,
        "effectiveness": json[254][1],
        "hasMarketingPlan": function(){ //To account for yep/yes/ya/yeah/y etc just in case this is later expected to have exactly "Yes" or "No"
          if((!json[253][1] || json[253][1].toString().toLowerCase().includes("y")) || (!json[253][1] || json[253][1].toString().toLowerCase().includes("true"))){
            return "Yes"
          }
          return "No"
        }(),
        "hasMarketingPoc": function(){ //To account for yep/yes/ya/yeah/y etc just in case this is later expected to have exactly "Yes" or "No"
          if((!json[252][1] || json[253][1].toString().toLowerCase().includes("y")) || (!json[252][1] || json[253][1].toString().toLowerCase().includes("true"))){
            return "Yes"
          }
          return "No"
        }(),
        "marketingBudget": json[255][1],
        "marketPlaceBudget": json[257][1],
        "plannedSpend": json[256][1],
        "primaryContact": json[258][1],
        "started": function(){
          if(json[259][1] || json[254][1] || json[253][1] || json[252][1] || json[255][1] || json[257][1] || json[256][1] || json[258][1]){
            return true;
          } else {
            return false;
          }
        }()
      },
      "PartnerGoals": {
        "completed": false,
        "started": false
      },
      "PartnerMetrics": {
        "2016PartnerRevenue": json[20][1],
        "2017PartnerRevenue": json[19][1],
        "2018PartnerRevenue": json[18][1],
        "apnExecutiveSponsors": json[12][1],
        "companyRevenue": json[4][1],
        "companyWebsite": json[3][1],
        "competitivePresense": json[14][1],
        "completed": false,
        "currentApnPrograms": json[13][1],
        "employeeCount": json[6][1],
        "marketingStakeholdersCount": json[9][1],
        "officeLocation": json[5][1],
        "revenue2016ChangeInfluenced": json[20][2],
        "revenue2017ChangeInfluenced": json[19][2],
        "revenue2018ChangeInfluenced": json[18][2],
        "salesStakeholderCount": json[7][1],
        "started": function(){
          if(json[20][1] || json[19][1] || json[18][1] || json[12][1] || json[4][1] || json[3][1] || json[14][1] || json[13][1] ||
             json[6][1] || json[9][1] || json[5][1] || json[20][2] || json[19][2] || json[18][2] || json[8][1]){
            return true;
          } else {
            return false;
          }
        }(),
        "technicalStakeholdersCount": json[8][1]
      },
      "partnerName": "Fully Completed", //TODO: Not sure?
      "PartnerOpportunities": {
        "completed": false,
        "customerWinsCount2016": json[28][3],
        "customerWinsCount2017": json[28][2],
        "customerWinsCount2018": json[28][1],
        "opportunitiesCount2016": json[27][3],
        "opportunitiesCount2017": json[27][2],
        "opportunitiesCount2018": json[27][1],
        "started": function(){
          if(json[28][3] || json[28][2] || json[28][1] || json[27][3] || json[27][2] || json[27][1]){
            return true;
          } else {
            return false;
          }
        }()
      },
      "PartnerPlanning": {
        "alignMarketMapToAWS": json[68][1],
        "alignMarketOrganization": json[68][0],
        "awsMarketplaceAgreement": json[113][1],
        "awsMspAgreement": json[111][1],
        "awsNda": json[109][1],
        "awsPracticePlan": json[116][1],
        "awsReferenceAccounts": json[92][6],
        "awsResellerAgreement": json[110][1],
        "awsServicesMsa": json[112][1],
        "awsSolutionsSpace": json[115][1],
        "awsSuperpowers": json[68][6],
        "awsSupportLevel": json[114][1],
        "completed": false,
        "consultantsCertified": json[92][4],
        "describeBAS": function(){  //The form here is pretty tight, so they may have answered in one or more boxes in the row which are not in column B.
          var retStr = "";
          for(var i = 1; i < 7; i++){
            if(json[77][i]){
              if(!retStr.length){
                retStr = json[77][i];
              } else {
                retStr += "\n\n" + json[77][i]
              }
            }
          }
          if(retStr){
            return retStr
          } else {
            return undefined;
          }
        }(),
        "describeMS": function(){  //The form here is pretty tight, so they may have answered in one or more boxes in the row which are not in column B.
          var retStr = "";
          for(var i = 1; i < 7; i++){
            if(json[78][i]){
              if(!retStr.length){
                retStr = json[78][i];
              } else {
                retStr += "\n\n" + json[78][i]
              }
            }
          }
          if(retStr){
            return retStr
          } else {
            return undefined;
          }
        }(),
        "describePS": function(){  //The form here is pretty tight, so they may have answered in one or more boxes in the row which are not in column B.
          var retStr = "";
          for(var i = 1; i < 7; i++){
            if(json[80][i]){
              if(!retStr.length){
                retStr = json[80][i];
              } else {
                retStr += "\n\n" + json[80][i]
              }
            }
          }
          if(retStr){
            return retStr
          } else {
            return undefined;
          }
        }(),
        "describeSuperpowers": function(){  //The form here is pretty tight, so they may have answered in one or more boxes in the row which are not in column B.
          var retStr = "";
          for(var i = 1; i < 7; i++){
            if(json[81][i]){
              if(!retStr.length){
                retStr = json[81][i];
              } else {
                retStr += "\n\n" + json[81][i]
              }
            }
          }
          if(retStr){
            return retStr
          } else {
            return undefined;
          }
        }(),
        "describeTopVerticals": function(){  //The form here is pretty tight, so they may have answered in one or more boxes in the row which are not in column B.
          var retStr = "";
          for(var i = 1; i < 7; i++){
            if(json[79][i]){
              if(!retStr.length){
                retStr = json[79][i];
              } else {
                retStr += "\n\n" + json[79][i]
              }
            }
          }
          if(retStr){
            return retStr
          } else {
            return undefined;
          }
        }(),
        "enterprise": json[85][1],
        "executiveSummary": json[32][1],
        "gtmMaterial": json[117][1],
        "inDirectChannels": json[92][5],
        "lowerMidMarket": json[87][1],
        "map2016": json[38][3],
        "map2017": json[38][2],
        "map2018": json[38][1],
        "mdf2016": json[36][3],
        "mdf2017": json[36][2],
        "mdf2018": json[36][1],
        "msftLegacyRelationship": json[103][1],
        "otherPublicCloudBusinesses": json[104][1],
        "partnerMakeMoney": json[100][1],
        "percentFromPublicCloud": json[105][1],
        "poc2016": json[37][3],
        "poc2017": json[37][2],
        "poc2018": json[37][1],
        "promoCredits2016": json[39][3],
        "promoCredits2017": json[39][2],
        "promoCredits2018": json[39][1],
        "pss2016": json[40][3],
        "pss2017": json[40][2],
        "pss2018": json[40][1],
        "publicSector": json[68][5],
        "resellMarginImpact": json[101][1],
        "sacOpportunities": json[125][0],
        "sacStrengths": json[123][0],
        "sacThreat": json[125][1],
        "sacWeakness": json[123][1],
        "sellersAccredited": json[92][2],
        "smb": json[88][1],
        "started": true,
        "technicalSellersCertified": json[92][3],
        "tf2016": json[41][3],
        "tf2017": json[41][2],
        "tf2018": json[41][1],
        "upperMidMarket": json[86][1],
        "verticals": json[68][4]
      },
      "SalesAndTechnicalEnablement": {
        "associateCertificationsGoal": json[199][1],
        "associateCertificationsQ1": json[199][2],
        "associateCertificationsQ2": json[199][3],
        "associateCertificationsQ3": json[199][4],
        "associateCertificationsQ4": json[199][5],
        "awsBusinessTrainingQ1": json[197][2],
        "awsBusinessTrainingQ2": json[197][3],
        "awsBusinessTrainingQ3": json[197][4],
        "awsBusinessTrainingQ4": json[197][5],
        "awsBusinessTrainingsGoal": json[197][1],
        "awsTechnicalTrainingQ1": json[198][2],
        "awsTechnicalTrainingQ2": json[198][3],
        "awsTechnicalTrainingQ3": json[198][4],
        "awsTechnicalTrainingQ4": json[198][5],
        "awsTechnicalTrainingsGoal": json[198][1],
        "competencyProgramsGoal": json[202][1],
        "competencyProgramsQ1": json[202][2],
        "competencyProgramsQ2": json[202][3],
        "competencyProgramsQ3": json[202][4],
        "competencyProgramsQ4": json[202][5],
        "completed": false,
        "otherProgramsGoal": json[203][1],
        "otherProgramsQ1": json[203][2],
        "otherProgramsQ2": json[203][3],
        "otherProgramsQ3": json[203][4],
        "otherProgramsQ4": json[203][5],
        "professionalCertificationsGoal": json[200][1],
        "professionalCertificationsQ1": json[200][2],
        "professionalCertificationsQ2": json[200][3],
        "professionalCertificationsQ3": json[200][4],
        "professionalCertificationsQ4": json[200][5],
        "serviceDeliveryProgramsGoal": json[201][1],
        "serviceDeliveryProgramsQ1": json[201][2],
        "serviceDeliveryProgramsQ2": json[201][3],
        "serviceDeliveryProgramsQ3": json[201][4],
        "serviceDeliveryProgramsQ4": json[201][5],
        "started": true
      },
      "sectionsCompleted": 0,
      "userId": "Placeholder Name", // This name gets replaced on the lambda function
      "year": 1995 //This date gets replaced on the lambda function
    }
    report.sectionsCompleted = function(){ //Coagulate completion status of the above sections
      return report.BusinessExpansion.completed + report.CoSelling.completed + report.Foundation.completed
      + report.Marketing.completed + report.PartnerGoals.completed + report.PartnerMetrics.completed
      + report.PartnerOpportunities.completed + report.PartnerPlanning.completed + report.SalesAndTechnicalEnablement.completed
    }()
    console.log(report)
    this.setState({
      ...this.state,
      disableUpload: false,
      uploadLoading: false,
      importFile: report
    });
  }

  render() {
    console.log("rendering")
    const { handleSubmit, dispatch, history } = this.props;
    const { disableUpload, uploadLoading, importFile } = this.state;
    const calculatedOptions = getOptions(new Date().getFullYear());
    const ImportPartnerPlanForm = (
      <Form
        onSubmit={handleSubmit}
        actions={
          <div className="awsui-util-f-r">
            <Button
              onClick={() => {
                history.push('/');
              }}
              variant="link"
              text="Cancel"
            />
            <Button
              disabled={disableUpload}
              loading={uploadLoading}

              /* Upload button actions */
              onClick={handleSubmit(values => {
                const notificationId = uuidv4();
                values['importFile'] = importFile;
                values['notificationId'] = notificationId;
                dispatch(
                  createLoadingNotification({
                    notificationId,
                    content: `Creating a new business plan.`
                  })
                );
                dispatch(importPlan(values));
              })}
              variant="primary"
              text="Upload"
            />
          </div>
        }
      >
        <FormSection header="Import Business Plan">
          <div>
            <p>
              Import is a quick way to upload a partner business plan using the standard APN business plan template. It
              has already been formatted for you. <a href={this.state.link}>Download it here to get started.</a>
            </p>
          </div>
          <div>
            <p>If you need help formatting your business plan, follow these instructions:</p>
            <ol>
              <li>
                Download <a href={this.state.link}>APN business plan template.</a>
              </li>
              <li>Fill in the APN Business plan.</li>
              <li>Save updated business plan and rename it.</li>
              <li>Browse for your updated APN business plan.</li>
              <li>Click upload</li>
            </ol>
          </div>
          <div>
            <p>Once the business plan is successfully uploaded you can only edit it in the Business Planning Tool.</p>
            <p>
              <strong>IT CAN NOT BE IMPORTED AGAIN.</strong>
            </p>
          </div>
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <PolarisInputField
                label="Business Plan Name"
                name="reportName"
                placeholder="Enter name"
                hintText="Required"
              />
              <PolarisSelectField
                label="Year"
                name="year"
                options={calculatedOptions}
                placeholder="Please select a year."
                hintText="Required"
              />
              {/* I believe this to be a better option, the type field seems to be a little glitchy. However, I do not know how to implement it yet */}
              {/* <ButtonDropdown 
                items={calculatedOptions} 
                placeholder="Please select a year.">
              </ButtonDropdown> */}
              {/* <Field component={FileInput} name="importFile" /> */}
              <input
                type="file"
                accept=".xlsx"
                ref={input => {
                  this.filesInput = input;
                }}
                onChange={this.importXlsx}
              />
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    );
    return (
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={ImportPartnerPlanForm}
        notifications={<Notifications />}
      />
    );
  }
}

const mapStateToProps = state => {
  return { apiEndpoint: state.user.apiEndpoint };
};

const mapDispatchToProps = dispatch => {
  //TODO: Remove/change this
  return {
    dispatch
  };
};

const formDecoratedComponent = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: validateForm
})(ImportPartnerPlan);
const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(formDecoratedComponent);

export default withRouter(connectDecoratedComponent);
